<template>

    <div
        class="h-fit w-full relative"
        :class="[variant, size, {'error': this.hasError}]"
        v-bind="bindedInputFieldAttributes">

        <p v-if="label" class="mb-1">{{ label }}</p>

        <div
            class="input-field-container relative flex items-center rounded transition-[boxShadow]"
            :class="[{'focus': focus}, {'cursor-pointer': this.isSelector}, {'rounded-b-none': this.isSelector && focus && this.filteredItemsBySearch?.length}]">

            <i v-if="icon" class="input-field-icon" :class="icon"/>
            <img v-if="image" class="input-field-icon" :src="image"/>

            <!----- Hint text ----->
            <div class="contents" v-if="hintText">

                <p class="shrink-0 text-xs">{{ $st(hintText) }}</p>
                <div class="h-6 w-px bg-main-100/20 shrink-0"/>

            </div>

            <!----- Phone Prefix ----->
            <input v-if="type === 'tel'" class="bg-transparent" style="width: 3ch" @click.stop :value="prefix" @input="$emit('update:prefix', $event.currentTarget.value)" v-bind="bindedInputAttributes">
            <div v-if="type === 'tel'" class="h-[24px] w-[1px] bg-current opacity-50 shrink-0"/>

            <!---------- Selected items ---------->
            <div
                v-if="selectedItems?.length && !focus"
                class="flex items-center gap-2 overflow-hidden">

                <div v-if="selectedItems?.length > 1" class="truncate">

                    {{ selectedItems.map(i => i.label).join(', ') }}

                </div>

                <p v-else class="flex items-center truncate" v-html="selectedItems[0]?.label"/>

            </div>

            <div v-else class="contents">

                <input
                    v-if="!textarea"
                    class="input-field-input h-full w-full bg-transparent outline-none transition-colors cursor-text"
                    v-model="this.computedValue"
                    @keydown.enter="this.$emit('enter')"
                    @click.stop
                    :type="showPassword ? 'text' : type"
                    v-bind="bindedInputAttributes"/>

                <textarea
                    v-else
                    v-model="this.computedValue"
                    class="input-field-input min-h-[80px] w-full bg-transparent outline-none transition-colors py-3 scroll-hidden cursor-text"
                    @keydown.enter="this.$emit('enter')"
                    @click.stop
                    v-bind="bindedInputAttributes"/>

            </div>

            <i
                v-if="quickClean && value"
                class="icon-cross-rounded text-current text-lg cursor-pointer hover:scale-105 active:scale-100 origin-center transition-transform"
                @click="value = undefined"/>

            <slot/>
            
            <i v-if="this.isSelector" class="icon-chevron-down text-base ml-auto"/>

            <i
                v-else-if="type === 'password'"
                class="password-icon cursor-pointer"
                :class="showPassword ? 'icon-eye-open' : 'icon-eye-closed'"
                @click.stop="showPassword = !showPassword"/>

        </div>

        <div v-if="this.isSelector && focus && this.filteredItemsBySearch?.length" class="absolute w-full top-full left-0 z-30">

            <ScrollShadow>
                
                <div class="input-field-menu-container max-h-[160px] flex flex-col rounded-b overflow-y-auto scroll-main !border border-transparent overflow-hidden">

                    <div
                        v-for="item in this.filteredItemsBySearch" :key="item"
                        class="input-field-menu-item group flex items-center gap-2 transition-[background] duration-200 overflow-hidden shrink-0"
                        :class="{'selected': this.IsSelected(item)}"
                        tabindex="0"
                        @keydown.enter="handleItemClick(item)"
                        @click.stop="handleItemClick(item)">

                        <p class="truncate" v-html="item.label" :title="item.label"/>

                        <div class="h-full flex items-center gap-2 ml-auto">

                            <div
                                v-if="item.island?.length"
                                class="input-field-menu-item-island hidden group-hover:flex animate-fade">

                                <i v-for="islandItem in item.island" :key="islandItem" v-bind="islandItem" class="button-hover text-sm p-1"/>
                            
                            </div>

                            <i class="hidden [.selected_&_]:!block icon-check text-2xs"/>

                        </div>

                    </div>

                </div>

            </ScrollShadow>

        </div>

        <InfoComponent :data="info" class="mt-1.5"/>
        
    </div>

</template>

<script>
import BaseInputComponent from './BaseInputComponent.vue'
import ScrollShadow from '@/components/ScrollShadow'

export default {

    extends: BaseInputComponent,

    props: {

        textarea: { type: Boolean, default: false },
        prefix: { type: String, default: '+34' },
        size: { type: String, default: 'md' },
        variant: { type: String, default: 'default' },
        type: { type: String, default: 'text', }, // Input type
        autofocus: { type: Boolean, default: false },
        min: { type: Number, default: undefined }, // Min characters or num
        max: { type: Number, default: undefined }, // Max characters or num
        allButton: Boolean,
        quickClean: Boolean
    },
    
    emits: ['update:prefix', 'focus', 'enter', 'input'],

    components: { ScrollShadow }, // eslint-disable-line

    data: function () {

        return {

            focus: false,
            search: undefined,
            showPassword: false,
        }
    },

    computed: {

        bindedInputFieldAttributes() {

            if ( !this.isSelector ) { return }

            return {

                onfocus: this.onFocus,
                tabindex: 0,
                'focus-group': this.componentID
            }
        },

        bindedInputAttributes() {

            return {

                onfocus: this.onFocus,
                oninput: () => this.$nextTick(() => this.$emit('input', this.value)),
                disabled: this.disabled || (this.isSelector && !this.searchInSelector),
                spellcheck: false,
                placeholder: this.computedPlaceholder,
                'focus-group': this.componentID
            }
        },

        isSelector() { return !!(this.items?.length) },

        searchInSelector() { return this.items?.length > 5 },

        computedPlaceholder() {

            return this.placeholder
            ? this.$st(this.placeholder)
            : this.$st(this.isSelector
                ? (this.focus && this.searchInSelector)
                    ? 'general.search'
                    : 'general.select'
                : 'general.type_here')
        },

        computedValue: {

            get() {

                if ( this.isSelector ) { return this.searchInSelector ? this.search : undefined }
                else return this.value
            },
            
            set(value) {
                
                if ( this.isSelector ) { this.searchInSelector ? this.search = value : undefined }
                else this.value = value
            }
        },

        computedSearch() { return this.search.replaceAll(' ', '').toLowerCase() },

        filteredItemsBySearch() {
            
            if ( !this.search ) return this.items

            const items_ = this.items?.filter(i => i.label.toString().replaceAll(' ', '').toLowerCase().includes(this.computedSearch) )

            if ( this.allButton && items_ ) { items_.unshift({ label: 'Todo', click: () => this.value = undefined }) }

            return items_
        },

        selectedItems() {
            
            if ( !this.isSelector ) return
            return this.items?.filter(i => this.multiple ? this.value.includes(i.value) : (this.value !== undefined && i.value === this.value))
        },

        passwordLevel() {

            return this.PasswordLevel()
        },
    },

    created() {

        if ( this.isSelector && this.multiple && !Array.isArray(this.value) ) { this.value = [] }

        if ( this.type === 'date' && this.value ) {

            const date = new Date(this.value)
            
            if ( isNaN(date.getTime()) ) {
                
                const splittedDate = this.value.split('/')
                this.value = `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`

            } else { this.value = date.toISOString().split('T')[0] }
        }
    },

    mounted() {

        if ( this.autofocus ) { this.$el.querySelector('input', 'textarea')?.focus() }

        if ( this.prefix ) this.$emit('update:prefix', this.prefix)
    },

    methods: {

        onFocus: function() {

            this.$emit('focus')
            this.focus = true
            this.$handleFocusGroup(this.onComponentBlur)
        },

        handleItemClick: function(item) {

            const val = item?.value

            if ( this.disabled ) { return }

            if ( item.click instanceof Function ) { item.click() }

            if ( this.multiple ) {

                if ( !this.value ) this.value = []

                const index = this.value.indexOf(val)

                if ( index > -1 ) { this.value.splice(index, 1) }
                else if ( this.multiple ) { this.value.push(val) }
                else { this.value = [val] }
            
            } else { this.value = this.value === val ? undefined : val; this.focus = false }

            this.$emit('input', this.value)
        },

        onComponentBlur: function() {

            this.focus = false
            this.search = undefined
        },

        IsSelected: function(item) {

            if ( this.multiple ) { return this.value?.includes(item.value) }
            else { return (this.value !== undefined && item.value === this.value) }
        }
    }
}
</script>

<style scoped>
@tailwind base;
@tailwind utilities;

.floating-shadow-element::before {

    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: theme('boxShadow.weak_material');
    pointer-events: none
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
    font-weight: inherit;
    font-size: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/*========== Sizes ==========*/
.lg {
    font-size: 14px;
}

.lg .input-field-container {
    height: 48px;
    padding: 0 14px;
    gap: 12px;
}

.lg .input-field-menu-container {
}

.lg .input-field-menu-container .input-field-menu-item {
    height: 36px;
    padding: 0 16px;
    font-size: 12px;
}

.md {
    font-size: 12px;
}

.md .input-field-container {
    height: 40px;
    padding: 0 14px;
    gap: 12px;
}

.md .input-field-menu-container {
}

.md .input-field-menu-container .input-field-menu-item {
    height: 36px;
    padding: 0 16px;
    font-size: 12px;
}

.sm {
    font-size: 12px;
}

.sm .input-field-container {
    height: 36px;
    padding: 0 12px;
    gap: 12px;
}

.sm .input-field-menu-container {
}

.sm .input-field-menu-container .input-field-menu-item {
    height: 36px;
    padding: 0 16px;
    font-size: 12px;
}

.sm {
    font-size: 12px;
}

.xs .input-field-container {
    height: 28px;
    padding: 0 12px;
    gap: 12px;
}

.xs .input-field-menu-container {
}

.xs .input-field-menu-container .input-field-menu-item {
    height: 36px;
    padding: 0 12px;
    font-size: 12px;
}

.lg .input-field-container .input-field-icon, .md .input-field-container .input-field-icon {
    height: 20px;
    font-size: 24px;
}

.sm .input-field-container .input-field-icon {
    height: 20px;
    font-size: 20px;
}

.xs .input-field-container .input-field-icon {
    height: 20px;
    font-size: 18px;
}

.lg .input-field-container .password-icon, .md .input-field-container .password-icon { font-size: 24px }
.sm .input-field-container .password-icon, .xs .input-field-container .password-icon { font-size: 20px }

.xs .label { font-size: 12px }


/*========== Default ==========*/
.default .input-field-container {
    @apply bg-main-10 border border-main-100/5 text-main-100;
}

.default.error .input-field-container {
    border-color: theme('colors.error.100');
}

.default.error .input-field-icon {
    color: theme('colors.error.100');
}

.default .input-field-container:not(.focus):hover {
    box-shadow: 0 0 0 1px inset theme('colors.second.75');
}

.default .input-field-menu-container {

    @apply bg-main-10 text-main-100 border-main-100/10;
}

.default .input-field-menu-container .input-field-menu-item {

    @apply bg-main-50/10 hover:bg-main-50/20 active:!bg-main-50/40;
    cursor: pointer;
}


.default .input-field-menu-container .input-field-menu-item.selected {

    @apply bg-second-10;
    cursor: pointer;
}

.default .input-field-menu-container .input-field-menu-item:not(.selected):focus {

    @apply bg-main-50/20;
}

/*========== Outlined ==========*/
.outlined .input-field-container {
    border: 1px solid theme('colors.main.100');
    color: theme('colors.main.100');
}

.outlined .input-field-input::placeholder {
    color: theme('colors.main.75');
}

.outlined:hover .input-field-container {}

.outlined .focus.input-field-container {
    border-color: theme('colors.second.100');
}

.outlined.error .input-field-container {
    border-color: theme('colors.error.50') !important;
}

.outlined.error .input-field-icon {
    color: theme('colors.error.50') !important;
}

/*========== Outlined light ==========*/

.outlined_light .input-field-container {
    @apply border border-main-5 text-main-5;
}

.outlined_light .input-field-container input::placeholder,
.outlined_light .input-field-container textarea::placeholder {
    @apply text-main-5/80;
}

.outlined_light:hover .input-field-container {}

.outlined_light .focus.input-field-container {
    /* border-color: theme('colors.second.100'); */
}

.outlined_light.error .input-field-container {
    border-color: theme('colors.error.50') !important;
}

.outlined_light.error .input-field-icon {
    color: theme('colors.error.50') !important;
}

/*========== Plain Secondary ==========*/
.plain_secondary .input-field-container {
    background: transparent;
    border: none;
    border-bottom: 1px solid theme('colors.second.100');
    color: theme('colors.second.100');
    font-weight: 500;
    padding: 0;
    border-radius: 0;
}

.plain_secondary .input-field-input::placeholder {
    color: theme('colors.second.50');
    font-weight: 400;
}

.plain_secondary:hover .input-field-container {}

.plain_secondary .focus.input-field-container {
    color: theme('colors.second.100');
    border-bottom-color: theme('colors.second.100');
}

/*========== Label ==========*/

/*========== Input ==========*/
.default .input-field-input::placeholder { color: theme('colors.main.50') }

textarea { resize: none }

input:disabled, textarea:disabled { pointer-events: none }

input::-ms-reveal,
input::-ms-clear { display: none }

* .input-field-container:has(textarea) { height: fit-content !important; }
</style>