<template>

    <div class="w-full py-4">

        <div class="relative bg-wg-50 w-full h-56 flex flex-col items-center justify-center rounded-2xl text-main-100 overflow-hidden">

            <img src="@/assets/gifs/globalLoading2.gif" alt="">
            <p class="absolute bottom-5 text-xs animate-fade">{{ this.store.state.flowLauncherController?.ui?.loadingMessage }}</p>

        </div>

    </div>

</template>

<script>
import store from '@/store'

export default {

    data: function() {

        return {

            store
        }
    }
}
</script>