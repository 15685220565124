import parsePhoneNumber from 'libphonenumber-js'
import i18n from '@/i18n.js'

export const validation = {

    empty: (value) => { return (value && value.toString().trim()) ? false : i18n?.global.t('validation.mandatory') ?? 'El campo es obligatorio.' },

    format: (value, type) => {

        if ( !value || !type ) { return false }

        switch(type) {

            case 'email':
    
                if ( !String(value).toLowerCase().match( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ) {

                    return i18n?.global.t('validation.email-format') ?? 'El formato del email es incorrecto.'
                }

                break

            case 'password':

                if ( validation.password(value) !== 'strong' ) {
                
                    return i18n?.global.t('validation.standard-password') ?? 'La contraseña debe incluir al menos 8 caracteres, un número, una mayúscula y un símbolo especial.'
                }

                break

            case 'dni': {

                const letter = value[value.length - 1]
                const number = value.slice(0, -1)
                const lettersOrder = 'TRWAGMYFPDXBNJZSQVHLCKET'

                const validDNI = !isNaN(number) && number.length === 8 && lettersOrder[parseInt(number) % 23] === letter.toUpperCase()

                if ( !validDNI ) {

                    return i18n?.global.t('validation.dni-format') ?? 'El formato del dni es incorrecto.'
                }

                break
            }

            case 'number':

                if ( isNaN(value) ) {

                    return i18n?.global.t('validation.nan') ?? 'El valor introducido no es un número.'
                }

                break
    
            case 'tel': {

                let valid = false
    
                try { valid = parsePhoneNumber(value, 'ES')?.isValid() }
                catch (e) {/**/}

                if ( !valid ) {

                    return i18n?.global.t('validation.phone-format') ?? 'El formato del teléfono es incorrecto.'
                }

                break
            }

            case 'date':

                if ( isNaN(new Date(value).getTime()) ) {

                    return i18n?.global.t('validation.date-format') ?? 'La fecha introducida es incorrecta.'
                }

                break
    
            case 'url': {

                let valid = true
    
                try { new URL(value); valid = value.includes('//') }
                catch(err) { valid = false }

                if ( !valid ) {
                    
                    return i18n?.global.t('validation.url-format') ?? 'El formato de la url es incorrecto.'
                }

                break
            }
        }
    },

    password: (value) => {

        if ( !value ) { return }

        const medium = /^(?=.*[A-Z])(?=.*\d).*$/
        const strong = /^(?=.*[\W_])(?=.*\d)(?=.*[A-Z]).*$/

        if ( value.length >= 8 && strong.test(value) ) { return 'strong' }
        if ( value.length >= 8 && medium.test(value) ) { return 'medium' }

        return 'weak'
    }
}

export default validation