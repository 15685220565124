import { createStore } from 'vuex'
import packageInfo from '../package'
import i18n from '@/i18n'
import { GetActiveUpdatesNote } from '@/helpers/APIconnection'

const store = createStore({

    state: {

        appVersion: packageInfo.version,

        customSidebarArguments: undefined,

        flowLauncherController: {},
        ui: {},
    },

    getters: {

        lang: () => { return i18n.global.locale.value },

        currentUser: state => { return state.currentUser },

        currentUserConfig: state => { return state.currentUser?.userData?.config ?? {} },

        currentUserSubscription: state => {

            const subscription = { active: false }

            if (state.currentUser?.subscription?.stripeSub) {

                const userSubscription = state.currentUser.subscription
                const stripeSub = userSubscription.stripeSub

                // Suscripción de prueba
                if (stripeSub.status === 'trialing') { subscription.trialing = true }

                subscription.active = (stripeSub.status === 'active' || stripeSub.status === 'trialing') && (stripeSub.currentPeriodEnd && (new Date(stripeSub.currentPeriodEnd * 1000) >= new Date()))

                if (subscription.active) {

                    subscription.cancelled = stripeSub.cancelAt ? true : false
                    subscription.nextPaymentDate = stripeSub.currentPeriodEnd ? stripeSub.currentPeriodEnd * 1000 : new Date().getTime()

                } else { subscription.nextPaymentDate = new Date().getTime() }

                subscription.name = userSubscription.name // Plan name
                subscription.ref = userSubscription.ref

                const { usageLimit, stripePaymentMethods, extraUsagePrice, basePrice, scheduleSubNextPhaseRef, scheduleSubNextPhaseName } = userSubscription
                Object.assign(subscription, { usageLimit, stripePaymentMethods, extraUsagePrice, basePrice, scheduleSubNextPhaseRef, scheduleSubNextPhaseName })

                subscription.usedSlans = state.currentUser.usedSlans ?? 0
                const balance = (subscription.usageLimit ?? 0) - (subscription.usedSlans ?? 0)
                subscription.balance = Math.max(balance, 0) // Current balance

                if (usageLimit) {

                    if (balance < 0) {

                        subscription.excededSlans = Math.abs(balance) // Exceded slans
                        subscription.extraPrice = (subscription.extraUsagePrice ?? 0) * subscription.excededSlans
                    }

                    subscription.slansPercentage = (subscription.usedSlans ?? 0) * 100 / subscription.usageLimit

                    if (subscription.slansPercentage >= 90) { state.ui.subscriptionBanner = true }
                }
            }

            return subscription
        },

        appVersion: state => { return state.appVersion },
    },

    mutations: {

        // ========== USER DATA METHODS ==========

        SetCurrentUser(state, data) {

            state.currentUser = data
            const user = data.userData
            const subscription = store.getters.currentUserSubscription

            const userFlow = async () => {

                // ==========| Payment onboarding |==========

                if (user && (!subscription.active || subscription.trialing)) {

                    const storageKey = `${user.uid}-pw`

                    const previousPaymentWall = localStorage.getItem(storageKey)

                    // Limit payment wall to 1 per day
                    if (!previousPaymentWall || (new Date().getTime() > (Number(previousPaymentWall) + 86400000))) {

                        // Await for onboarding promise return
                        await new Promise(res => state.ui.paymentOnboardingPromise = res).then(() => {

                            localStorage.setItem(storageKey, new Date().getTime())
                            state.ui.paymentOnboardingPromise = undefined
                        })
                    }
                }

                // [MODAL] - Get active updates note
                const slangoNews = await GetActiveUpdatesNote()
                if (slangoNews?.status === 200 && slangoNews?.data) { state.ui.slangoNews = slangoNews.data; return }
            }

            userFlow()
        }
    },

    actions: {}
})

export default store;