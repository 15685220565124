<template>

    <div v-if="data?.text" class="w-full flex items-center gap-2 py-1.5 px-2 text-xs pointer-events-none mt-1 bg-error-10 text-error-100 rounded">
        
        <i class="icon-warning text-lg"></i>
        {{ data.text }}
    
    </div>

</template>

<script>
export default {

    props: { data: Object },
}
</script>