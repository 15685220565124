<template>

    <Modal class="text-sm" :bully="false" :overflow="false" :onclose="this.onclose">

        <div class="overflow-y-auto scroll-main text-main-100">

            <p class="text-2xl">Elige fecha y hora</p>
            <p class="mt-2">Y programa la tarea para que se lance automáticamente cuando tu necesites.</p>

            <div class="h-fit flex gap-4 mt-8">

                <Calendar
                    class="h-fit w-[300px]"
                    :defaultStyle="false"
                    :repeatInterval="this.data.interval"
                    :intervalTime="this.data.repeat && this.data.every ? this.data.every : undefined"
                    v-model="this.data.date"/>

                <div class="w-px bg-main-100/10 shrink-0"/>

                <div class="grow max-w-[160px] flex flex-col gap-10">

                    <div class="flex items-center gap-3 mx-auto">
                        
                        <RangeSelector
                            v-model="this.data.hour"
                            :range="[0, 23]"
                            :vertical="true"
                            :normalize="(value) => value?.toString().padStart(2, 0)"/>

                        <p>:</p>

                        <RangeSelector
                            v-model="this.data.minute"
                            :range="[0, 59]"
                            :vertical="true"
                            :normalize="(value) => value?.toString().padStart(2, 0)"/>

                    </div>

                    <!-- <div class="bg-second-50/40 rounded py-2 px-3 border border-second-100/10 text-xs">{{ this.$formatDate({ date: new Date(), format: 'dd/mm/yyyy hh:mm', div: '.' }) }}</div> -->

                    <div class="flex flex-col gap-3 border-l-2 border-second-50 pl-4">

                        <div class="flex items-center gap-3">

                            Repetir
                            <Toggle v-model="this.data.repeat" @click="this.data.repeat = !this.data.repeat"/>

                        </div>

                        <div class="flex items-center gap-2.5" :class="{'opacity-50 pointer-events-none': !this.data.repeat}">

                            Cada

                            <span>

                                <InputField v-model="this.data.every" size="xs" placeholder="" type="number"/>

                            </span>

                            <div
                                class="flex items-center gap-1.5 cursor-pointer"
                                tabindex="0"
                                @focus="this.$handleFocusGroup(() => showIntervalSelector = false)"
                                focus-group="scheduler-interval-group"
                                @click="showIntervalSelector = !showIntervalSelector">

                                {{ intervalTime[this.data.interval] }}
                                <i class="icon-chevron-down text-2xs"/>

                                <FloatingElement v-if="showIntervalSelector" :position="{ top: 4, right: 0, translateY: true }">

                                    <div class="py-1 bg-main-10 rounded shadow-weak_material" focus-group="scheduler-interval-group">

                                        <p
                                            v-for="i, key in intervalTime" :key="i"
                                            class="px-3 py-1 hover:bg-main-50/10 text-xs cursor-pointer"
                                            :class="{'hidden': key === this.data.interval}"
                                            @click="this.data.interval = key">
                                            
                                            {{ i }}
                                        </p>
                                        
                                    </div>

                                </FloatingElement>

                            </div>

                        </div>
                        
                    </div>

                </div>

            </div>

            <div class="flex items-center justify-end gap-4 mt-6">

                <Button size="sm" variant="outlined" :text="this.$st('general.cancel')" @click="this.onclose"/>
                <Button size="sm" variant="primary" text="Programar tarea" @click="this.OnSchedule" :loading="loading"/>

            </div>

        </div>

    </Modal>

</template>

<script>
import Calendar from '@/components/Calendar'
import Modal from '@/slango-multiverse/components/Modal'
import FloatingElement from '@/slango-multiverse/components/FloatingElement'
import Button from '@/slango-multiverse/components/inputs/Button'
import Toggle from '@/slango-multiverse/components/inputs/Toggle'
import InputField from '@/slango-multiverse/components/inputs/InputField'
import RangeSelector from '@/slango-multiverse/components/inputs/RangeSelector.vue'

export default {

    components: { Modal, FloatingElement, Calendar, Button, Toggle, InputField, RangeSelector }, // eslint-disable-line

    props: { onclose: Function, onSchedule: Function },

    data: function() {

        return {

            loading: false,

            showIntervalSelector: false,

            data: {

                every: 1,
                interval: 'days',
                hour: 0,
                minute: 0
            },

            intervalTime: { hours: this.$st('utils.hour', 2), days: this.$st('utils.day', 2), months: this.$st('utils.month', 2) }
        }
    },

    created() {

        this.data.hour = new Date().getHours()
    },

    methods: {

        OnSchedule: async function() {

            this.loading = true

            await this.onSchedule?.(this.data)

            this.loading = false
        }
    }
}
</script>