// const tailwind = require('../slango-multiverse/slango-tailwind.js')

// const colors = Object.fromEntries(Object.entries(tailwind.theme.extend.colors).flatMap(colorGroup => {

//     if ( typeof colorGroup[1] === 'string' ) return []

//     return Object.entries(colorGroup[1]).map(color => [`${colorGroup[0]}-${color[0]}`, color[1]])
// }))

const colors = {
    
    main: '#011956',
    second: '#219EBC',
    cta: '#D0098D',
    cta2: '#FFB703',
    wg: '#FFF'
}

const slango_light = {

    dark: false,
    colors
}

const theme = {

    defaultTheme: 'slango_light',

    themes: { slango_light },
}

export default theme