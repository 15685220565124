<template>

    <Collapsible :self=true>

        <div
            v-if="currentLog"
            :key="currentLog?._id"
            class="absolute left-1/2 bottom-0 -translate-x-1/2 max-md:w-full md:min-w-[600px] max-w-3xl bg-white border border-second-50 shadow-[0_0_12px_2px_theme(colors.second.100)] rounded-t-3xl transition-[box-shadow,height] duration-500 z-50"
            @click="ToggleNotification"
            :class="[{'hover:shadow-[0_0_20px_5px_theme(colors.second.100)]': !open}]">

            <div class="p-6">

                <div class="flex flex-col gap-4">

                    <div class="flex items-center justify-between gap-4">

                        <div class="flex items-center gap-3 overflow-hidden">

                            <div
                                v-if="currentLog.infoByStatus?.chip"
                                class="h-6 w-6 flex items-center justify-center rounded-full shrink-0"
                                :class="currentLog.infoByStatus?.chip.class"
                                v-tooltip="currentLog.infoByStatus.description">

                                <i class="" :class="currentLog.infoByStatus?.chip.icon"/>

                            </div>

                            <v-chip
                                v-if="!currentLog.robotName"
                                color="main"
                                text="Este robot ya no forma parte de nuestra familia"
                                size="x-small"/>

                            <p v-else-if="currentLog.isNotification" class="text-base">Se ha lanzado el robot <span class="font-bold">{{ currentLog.robotName }}</span></p>
                            <p v-else class="text-base font-bold">{{ currentLog.robotName }}</p>

                        </div>

                        <v-chip
                            :color="currentLog.scheduled ? 'cta' : 'second'"
                            :text="currentLog.executionType"
                            size="x-small"/>
                        
                    </div>

                    <div class="flex items-center justify-between [&:not(:has(:nth-child(2)))]:!justify-center gap-2 text-xs">

                        <v-chip
                            v-if="hasInfoMessages"
                            color="main-50"
                            :text="open ? 'Haz click para ver menos detalles' : 'Haz click para ver más detalles'"
                            size="x-small"/>

                        <div class="flex items-center gap-4 ml-auto">

                            <div v-if="currentLog.usedMachine" class="flex items-center gap-2 text-xs">

                                <i class="icon-desktop text-base"/>
                                <p>{{ currentLog.usedMachine.name }}</p>

                            </div>

                            <div class="flex items-center gap-2 text-xs">

                                <i class="icon-calendar text-sm"/>
                                {{ currentLog.createdAtString }}

                            </div>

                        </div>

                    </div>

                </div>

                <Collapsible :collapsed="!open" v-if="hasInfoMessages">

                    <div class="w-full flex flex-col items-start gap-3 mt-6 text-xs border-t border-main-10">

                        <v-timeline side="end" density="comfortable" truncate-line="end">

                            <v-timeline-item v-for="msg in currentLog.normalizedUserLogStreams" :key="msg" :dot-color="msg.level" size="x-small">

                                <p>{{ msg.text }}</p>

                                <template #opposite>{{ $formatDate({ date: msg.timestamp, format: 'hh:mm:ss' }) }}</template>

                            </v-timeline-item>

                        </v-timeline>

                    </div>

                </Collapsible>

            </div>
            
        </div>

    </Collapsible>

</template>

<script>
import { globalProperties } from '@/main.js'
import store from '@/store.js'
import Collapsible from '@/slango-multiverse/components/Collapsible.vue'

const chips = {

    success: { class: 'text-second-100 bg-second-100/15', icon: 'icon-check text-xs' },
    warning: { class: 'text-cta2-100 bg-cta2-100/15', icon: 'icon-less text-3xl' },
    neutral: { class: 'text-main-100 bg-main-100/15', icon: 'icon-less text-3xl' },
    error: { class: 'text-cta-100 bg-cta-100/15', icon: 'icon-cross text-2xl' }
}

export const FlowDetailsController = {

    NormalizeLogData: (response) => {

        if ( response.stoppedByUser ) { response.status = 202 } // stopped by user
        else if ( response.result === 0 || response.result === 'Success' ) { response.status = 200 } // success
        else if ( response.result === 1 || response.result === 'Fail' ) { response.status = 500 } // server error
        else if ( response.result === 2 || response.result === 'WrongInput' ) { response.status = 400 } // wrong input, file open or wrong columns
        else if ( response.result === 3 || response.result === 'NotEnoughSlans' ) { response.status = 402 } // usage limit reached
        else if ( response.result === 4 || response.result === 'FlowAborted' ) { response.status = 299 } // execution aborted

        response.createdAtString = globalProperties.$formatDate({ date: response.createAt || new Date(), format: 'dd/mm/yy hh:mm' }).replace(' ', ' • ')
        response.relation = (response.successfulIterations / response.totalIterations * 100).toFixed(1)

        response.executionType = response.browserFlowSchedule?.machine
            ? 'Lanzamiento remoto'
            : response.scheduled
                ? 'Programado'
                : 'Manual'

        if ( response.status === 200 ) response.statusChip = 'icon-check text-lg [*_&]:bg-cta-100'
        else if ( response.status === 202 || response.status === 402 ) response.statusChip = { class: 'text-4xl text-cta2-100 bg-cta2-100/15', icon: 'icon-less' }
        else response.statusChip = { class: 'text-lg text-second-100 bg-second-100/15', icon: 'icon-check' }

        if ( response.status in FlowDetailsController.infoByStatus ) response.infoByStatus = FlowDetailsController.infoByStatus[response.status]

        response.usedMachine = response.browserFlowSchedule?.machine

        response.cost = 0
        response.robotName = globalProperties.$getParameterCaseInsensitive(response.browserFlow, 'name')
        
        const logStreamVariableKey = globalProperties.$getParameterCaseInsensitive(response.browserFlow, 'logStreamVariableKey')

        if ( logStreamVariableKey && logStreamVariableKey in response.logStreams ) { response.normalizedUserLogStreams = response.logStreams[logStreamVariableKey].filter(l => l.level) } // Log stream normalization
        if ( response.usageHistory?.usageUnits && response.usageHistory?.usageRatio ) { response.cost = response.usageHistory.usageUnits * response.usageHistory.usageRatio } // Usage

        return response
    },

    infoByStatus: {

        200: { text: 'Finalizado', description: 'El robot se ha ejecutado correctamente', color: 'error', chip: chips.success },
        202: { text: 'Robot detenido', description: 'El robot ha sido detenido por el usuario', color: 'main', chip: chips.neutral },
        400: { text: 'Entradas incorrectas', description: 'Las entradas del robot no son válidas', color: 'error', chip: chips.warning },
        402: { text: `Insuficientes ${store.state.currencies}`, description: `No dispones de ${store.state.currencies}`, color: 'main', chip: chips.warning },
        299: { text: 'Robot interrumpido', description: 'Robot interrumpido en su ejecución', color: 'error', chip: chips.error },
        500: { text: '', description: 'El robot no se ha lanzado correctamente', color: 'error', chip: chips.error }
    }
}

export default {

    components: { Collapsible },

    data: function() {

        return {

            open: false,
            logStreamsColors: {

                error: 'bg-error-100',
                warn: 'bg-cta2-100',
                log: 'bg-second-100',
                trace: 'bg-second-50'
            }
        }
    },

    computed: {

        hasInfoMessages() { return !!this.currentLog.normalizedUserLogStreams?.length },

        logs() { return store.state.ui.robotLogs },

        currentLog() {

            const log = this.logs?.[0]

            if ( !log ) return

            if ( !log.status ) { FlowDetailsController.NormalizeLogData(log) }
            
            return log
        }
    },

    watch: {

        currentLog() {

            this.open = false

            if ( this.currentLog ) {

                if ( !this.currentLog.isNotification ) { this.open = true }

                this.currentLog.timer = this.$timer({ interval: 6, callback: () => { store.state.ui.robotLogs.shift() } })

                this.currentLog.timer.start()
            }
        }
    },

    methods: {

        ToggleNotification: function() {

            if ( this.hasInfoMessages ) {

                this.open = !this.open

                if ( this.currentLog?.timer ) { this.open ? this.currentLog.timer.pause() : this.currentLog.timer.start() }
            }
        },
    }
}
</script>