<template>
    
    <div/>

</template>

<script>
import InfoComponent from '@/slango-multiverse/components/InfoComponent.vue'
import { secureTranslation } from '@/i18n'
import validation from '@/slango-multiverse/helpers/validations'

export default {

    components: { InfoComponent }, // eslint-disable-line

    props: {

        modelValue: {},
        propError: {},
        disabled: Boolean,
        required: Boolean,
        formatRequired: Boolean,
        multiple: Boolean,
        type: String,
        items: Array,
        placeholder: String,
        text: String,
        hintText: String,
        label: String,
        icon: String,
        image: String,
        related: Object,
        validationGroup: {}
    },

    emits: ['update:modelValue', 'error', 'actionCallback', 'created', 'getValue'],

    data: function() {

        return {

            value: undefined,
            info: undefined,
            translate: secureTranslation,
            componentID: Math.floor(Math.random() * 9000) + 1000
        }
    },

    computed: {

        PropError() { return this.propError },

        hasError() { return this.info?.type === 'error' }
    },

    watch: {

        modelValue: {

            handler() { this.value = this.modelValue },
            deep: true
        },

        value: {

            handler() { this.$emit('update:modelValue', this.value) },
            deep: true
        },

        error: {

            handler() { this.$emit('error') },
            deep: true
        },

        PropError() { this.info = this.propError },
    },

    created() {

        this.value = this.modelValue

        this.$emit('created', this)

        this.registerInput()
    },

    unmounted() {

        this.unregisterInput()
    },

    methods: {

        registerInput: function() {

            let group
            let excludeOfGeneralGroup

            if ( this.validationGroup ) {

                if ( Array.isArray(this.validationGroup) ) {

                    group = this.validationGroup[0]
                    excludeOfGeneralGroup = this.validationGroup[1]
                    
                } else { group = this.validationGroup }

                if ( !this.$registeredInputs[group] ) this.$registeredInputs[group] = {}

                this.$registeredInputs[group][this.componentID] = this
            }

            if ( !excludeOfGeneralGroup ) this.$registeredInputs.all[this.componentID] = this

            const parentComponent = this.$parent.$parent

            if ( !parentComponent.componentInfo ) {

                parentComponent.componentInfo = (group, info) => {

                    if ( group && this.$registeredInputs[group] ) Object.values(this.$registeredInputs[group]).forEach(c => c.info = info)
                }
            }
        },

        unregisterInput: function() {

            const inputs = this.$registeredInputs

            if ( inputs[this.validationGroup] ) delete inputs[this.validationGroup][this.componentID]
            delete inputs.all[this.componentID]
        },

        componentInfo: function(info) { this.info = info },

        createComponentError: function(error) { this.info = { type: 'error', text: error } },

        validate: function() {

            // Returns true if component has a valid value

            this.info = undefined

            if ( this.required ) {

                const error = validation.empty(this.value)

                if ( error ) {
                    
                    this.createComponentError(error)
                    return false
                }
            }

            if ( this.formatRequired ) {

                const error = validation.format(this.value, this.type)

                if ( error ) {
                    
                    this.createComponentError(error)
                    return false
                }
            }

            return true
        }
    }
}
</script>