<template>

	<div class="relative flex flex-col h-full w-full overflow-hidden" ref="container">

		<div
			class="absolute top-0 left-0 h-5 w-[calc(100%-2px)] bg-gradient-bt rounded-b-lg z-20 opacity-0 pointer-events-none transition-[opacity] duration-300"
			:class="{'opacity-100': showTopShadow}"/>

		<slot/>

		<div
			class="absolute bottom-0 left-0 h-5 w-[calc(100%-2px)] bg-gradient-tb rounded-t-lg z-20 opacity-0 pointer-events-none transition-[opacity] duration-300"
			:class="{'opacity-100': showBottomShadow}"/>

	</div>

</template>

<script>
export default {

	data: function() {

		return {

			showTopShadow: false,
			showBottomShadow: false
		}
	},

	mounted() {

		const element = this.$refs.container.children[1]

		if ( element ) {

			this.ShadowsByScroll(element)

			element.addEventListener('scroll', this.OnScroll)
		}
	},

	methods: {

		OnScroll: function(e) { this.ShadowsByScroll(e.target) },

		ShadowsByScroll: function(element) {

			if ( !element ) return

			this.showTopShadow = element.scrollTop !== 0
			this.showBottomShadow = (element.scrollTop + element.clientHeight) !== element.scrollHeight
		}
	}
}
</script>

<style>
.bg-gradient-tb { background: linear-gradient(transparent, rgba(0, 0, 0, 0.2)); }
.bg-gradient-bt { background: linear-gradient(rgba(0, 0, 0, 0.2), transparent); }
</style>