<template>

    <div class="anim-container">
        <div class="circle bg-current"></div>
        <div class="circle bg-current"></div>
        <div class="circle bg-current"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
    </div>

</template>

<style scope>
.anim-container {
    height: 35px;
    width: 100%;
    position: relative;
    display: flex;
}

.anim-container .circle {
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    transform-origin: center;
    left: 0;
}

.anim-container .circle:nth-child(1) {
    animation: bounce 0.5s alternate infinite ease;
    left: calc(50% - 23px);
}

.anim-container .circle:nth-child(2) {
    animation: bounce 0.5s alternate infinite ease;
    animation-delay: 0.15s;
    left: calc(50%);
}

.anim-container .circle:nth-child(3) {
    animation: bounce 0.5s alternate infinite ease;
    animation-delay: 0.30s;
    left: calc(50% + 23px);
}

@keyframes bounce {
    0% {
        top: 100%;
        height:5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }

    40% {
        height:10px;
        border-radius: 50%;
        transform: scaleX(1);
    }

    100% {
        top:0%;
    }
}
</style>