
export const desktopAppComunication = async (functionName, params = undefined) => {

    const response = await window.chrome?.webview?.hostObjects.config[functionName](...(params ? [JSON.stringify(params)] : [])).then(r => {

        try { return JSON.parse(r) }
        catch (e) { return r; }

    }).catch(e => console.log(e))
    
    return response
}

export const desktopPostMessage = async (command, data) => {

    window.chrome?.webview.postMessage(JSON.stringify({ command, data }));
}

export const desktopAddListener = async (callback) => {

    window.chrome?.webview.addEventListener('message', callback)
}

export const desktopRemoveListener = async (callback) => {

    window.chrome?.webview.removeEventListener('message', callback)
}

export const desktopAppData = window.chrome?.webview?.hostObjects.config

