const currencyFormater = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', });

export default {

    clipboard: (text) => { if (text) { navigator.clipboard.writeText(text) } },

    formatDate: (args) => {

        const options = {}

        // Date format shortcut
        if ( args.format?.includes('dd/mm/yyyy') ) {  options.day = '2-digit'; options.month = '2-digit'; options.year = 'numeric'  }
        else if ( args.format?.includes('dd/mm/yy') ) { options.day = '2-digit'; options.month = '2-digit'; options.year = '2-digit' }

        // Time format shortcut
        if ( args.format?.includes('hh:') ) { options.hour = '2-digit' }
        if ( args.format?.includes(':mm') ) { options.minute = '2-digit' }
        if ( args.format?.includes(':ss') ) { options.second = '2-digit' }
    
        const date = new Date(args.date)
        const formatedDate = date.toLocaleString('es-ES', options).replace(',', '')
        
        if ( args.div !== undefined ) { return formatedDate.replaceAll('/', args.div) }
    
        return formatedDate
    },

    download: (data) => {

        if ( !data.url ) {

            if ( data.blob ) { data.data = data.blob }
            if ( data.arraybuffer ) { /* Conversion */ }
    
            data.url = URL.createObjectURL(data.data)
        }
    
        if ( !data.filename ) { data.filename = data.url.split('/').reverse()[0] } // Build filename from url
        if ( data.extension ) { data.filename += data.extension } // Add extension to filename
    
        const a = document.createElement('a')
        a.setAttribute('href', data.url)
        a.setAttribute('download', data.filename)
        document.body.appendChild(a)
        a.click()
        a.parentNode.removeChild(a)
    
        URL.revokeObjectURL(data.url)
    },

    handleFocusGroup: (callback) => {

        const origin = document.activeElement
        const originFocusGroup = origin.getAttribute('focus-group')

        setTimeout(() => Array.from(document.querySelectorAll('*[focus-group]')).forEach(e => e.tabIndex = 0), 150)

        const inFocusGroup = (target) => {
    
            if ( target === null ) { callback(); return }
    
            let element = target
    
            // Iterate parent elements until reach group parent, if the focus remains in the group, the following element is monitored
            do {
    
                if ( origin === element || (originFocusGroup && element.getAttribute('focus-group') === originFocusGroup) ) { listenBlurEvent(target); return }
                element = element.parentElement
    
            } while (element)
    
            callback()
        }

        const listenBlurEvent = (target) => { target.addEventListener('blur', (e) => inFocusGroup(e.relatedTarget), { once: true }) }

        listenBlurEvent(origin)
    },

    formatBytes: (bytes) => {

        if ( !bytes ) return
        
        const thresh = 1000; // Base 1000, sistema internacional
    
        if ( Math.abs(bytes) < thresh ) { return bytes + ' B' }
    
        const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        let u = -1;
        const r = 100; // Obtener dos decimales
    
        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    
    
        return bytes.toFixed(2) + ' ' + units[u];
    },

    normalizeFilename: (filename) => { return filename.trim().replaceAll(/[/\\]/g, '-').replaceAll(/[?%*:|"<>]/g, '') },

    formatTimer: (seconds) => {

        const secs = seconds % 60
        const mins = (seconds - secs) / 60

        return `${mins.toString().padStart(2, 0)}:${secs.toString().padStart(2, 0)}`
    },

    prettyTime: (miliseconds, justHrsMins) => {

        const ms = miliseconds % 1000
        miliseconds = ( miliseconds - ms ) / 1000
        const secs = miliseconds % 60
        miliseconds = ( miliseconds - secs ) / 60
        const mins = miliseconds % 60
        const hrs = ( miliseconds - mins ) / 60

        if ( justHrsMins ) { return `<span>${hrs}h</span> <span>${mins}m</span>` }

        if ( hrs ) { return `<span>${hrs}h</span> <span>${mins}m</span> <span>${secs}s</span>` }
        else if ( mins ) { return `<span>${mins}m</span> <span>${secs}s</span>` }
        else { return `<span>${secs > 1 ? secs : 1}s</span>` }
    },

    currencyNumberFormat: (value) => currencyFormater.format(value).trim(),

    getParameterCaseInsensitive: (object, value) => {

        if ( !object ) return

        const capitalized = value.charAt(0).toUpperCase() + value.slice(1)

        return object[value] || object[capitalized]
    },
    
    horizontalDrag: (event, threshold = 6) => {
    
        const target = event.currentTarget
        const pos = { x: event.clientX }
        let dragAllowed = false
    
        const MouseMoveHandler = (e) => {
    
            const x = e.clientX

            if ( !dragAllowed ) {

                dragAllowed = Math.abs(x - pos.x) >= threshold
                return
            }

            const dx = x - pos.x
            pos.x = x
            target.scrollLeft = target.scrollLeft - dx

            document.body.style.cursor = 'grabbing'
            target.style.pointerEvents = 'none'
        }
    
        document.removeEventListener('mousemove', MouseMoveHandler)
    
        document.addEventListener('mousemove', MouseMoveHandler)
    
        document.addEventListener('mouseup', () => {

            document.removeEventListener('mousemove', MouseMoveHandler)
            document.body.style.cursor = 'auto'
            target.style.pointerEvents = 'auto'

        }, { once: true })
    },

    horizontalScroll: (e) => {

        e.currentTarget.scrollTo({ left: e.currentTarget.scrollLeft - e.wheelDelta })

        e.preventDefault()
    },

    navigationUnderlineMenu: (container, current) => {

        if ( !container ) { return }
    
        let underline = container.querySelector('#underline')
    
        if ( !underline ) {
    
            container.classList.add('relative')
            underline = document.createElement('div')
            underline.id = 'underline'
            underline.classList.add('bg-current')
            underline.style.position = 'absolute'
            underline.style.bottom = '0'
            underline.style.height = '1px'
            underline.style.transition = 'width 200ms, left 200ms'
            container.appendChild(underline)
        }
    
        current = container.children[current]
    
        underline.style.width = current.clientWidth + 'px'
        underline.style.left = current.offsetLeft + 'px'
    },

    cancelableTimeout: (timeoutContext, timeoutRef, duration, callback) => {

        clearTimeout(timeoutContext[timeoutRef])

        if ( duration && callback ) { timeoutContext[timeoutRef] = setTimeout(callback, duration) }
    },

    clickWithoutDrag: (mousedownEvent, callback) => {

        if ( !callback ) return

        const target = mousedownEvent.currentTarget

        let originPos

        const cancel = () => {

            target.removeEventListener('mouseup', onClick)
            window.removeEventListener('mousemove', onMove)
        }

        const onClick = () => { callback(); cancel() }

        const onMove = (e) => {

            if ( !originPos ) {

                originPos = { x: e.x, y: e.y }
                return
            }

            if ( e.x - originPos.x > 10 || e.y - originPos.y > 10 ) { cancel() }
        }

        target.addEventListener('mouseup', onClick, { once: true })
        window.addEventListener('mousemove', onMove)
    }
}