const XLSX = require('xlsx')

export const LoadExcelFiles = async(files, type = "" ) => {

    if ( !files ) { return }

    return Promise.all(files.map(file => {

        if ( !file ) return

        return new Promise(res => {

            const fileData = { name: file.name }
            const reader = new FileReader()

            reader.onload = (e) => {

                const data = new Uint8Array(e.target.result)
                const workbook = XLSX.read(data, { type: 'array', cellDates: true })

                const sheet = workbook.Sheets[workbook.SheetNames[0]]

                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }).map(row => Array.from(row))

                if ( type === "byColumns" ) { fileData.data = jsonData[0].map((r, index) => jsonData.map(r => r[index])) }
                else if ( type === "launchFlow" ) { 
                    const newData = jsonData.slice(1).reduce((acc, line) => {
                        if (line.length > 0) {
                            const newObject = jsonData[0].reduce((obj, key, index) => {
                                if (index < line.length) {
                                    obj[key] = line[index];
                                }
                                return obj;
                            }, {});
                            acc.push(newObject);
                        }
                        return acc;
                    }, []);
                    fileData.data = newData
                }
                else { fileData.data = jsonData }

                res(fileData)
            }

            reader.readAsArrayBuffer(file)
        })
    }))
}