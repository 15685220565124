<template>

    <div
        class="relative h-full flex flex-col items-center w-0 opacity-0"
        style="transition: width 300ms, margin 300ms, opacity 700ms"
        :class="{'!w-5 mr-3 !opacity-100': this.steps?.length}">

        <div
            v-for="step in this.steps" :key="step"
            class="relative w-4 shrink-0"
            :style="step.style">

            <div class="h-full w-px border border-second-10 border-dashed mx-auto"></div>

            <div class="absolute bottom-0 w-full aspect-square rounded-full border border-second-10 bg-second-50 translate-y-1/2 z-10"></div>

        </div>

    </div>

</template>

<script>
export default {

    data: function() {

        return {

            steps: undefined
        }
    },

    mounted() {

        this.resizeObserver = new ResizeObserver(() => {

            clearTimeout(this.timeout)
            this.timeout = setTimeout(this.BuildStepbar, 10)
        })

        this.resizeObserver.observe(this.$el.parentElement)
    },

    unmounted() { this.resizeObserver?.disconnect() },

    methods: {

        BuildStepbar: function() {

            const stepbarRect = this.$el.getBoundingClientRect()
            let lastPosition = stepbarRect.top

            const steps = Array.from(this.$el.parentElement.querySelectorAll('*[stepbar-column-step]'))
            this.steps = steps.map(stepElement => {

                const element = stepElement.querySelector('*[stepbar-column-anchor]') ?? stepElement

                const rect = element.getBoundingClientRect()
                const stepHeight = (rect.top + (rect.height / 2)) - lastPosition
                lastPosition += stepHeight
                
                return {
                    
                    element,
                    style: `height: ${stepHeight}px`,
                }
            })
        }
    }
}
</script>