<template>

    <div class="ham-menu-button" tabindex="0" :class="{'open': props.open}">

        <div/><div/><div/>

    </div>

</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({ open: Boolean })
</script>

<style scoped>
@keyframes ham-anim-open-1 {

    20% { transform: translateY(0) }
    40% { transform: rotate(45deg) }
    100% { transform: translateY(0) rotate(45deg) }
}

@keyframes ham-anim-open-3 {

    20% { transform: translateY(0) }
    40% { transform: rotate(-45deg) }
    100% { transform: translateY(0) rotate(-45deg) }
}

.ham-menu-button {
    
    height: 18px;
    width: 18px;
    position: relative;
    -webkit-app-region: no-drag;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ham-menu-button div {

    position: absolute;
    height: 2px;
    width: 100%;
    left: 0;
    background: theme('colors.main.5');
}

.ham-menu-button div:nth-child(2) { transition: transform 0.2s, opacity 0.2s; width: 80%; }

.ham-menu-button div:nth-child(1) { transform: translateY(-6px) }
.ham-menu-button div:nth-child(3) { transform: translateY(6px) }

.ham-menu-button.open div:nth-child(1) { animation: ham-anim-open-1 0.8s forwards }
.ham-menu-button.open div:nth-child(3) { animation: ham-anim-open-3 0.8s forwards }

.ham-menu-button.open div:nth-child(2) { transform: translateX(16px); opacity: 0 }
</style>