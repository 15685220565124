<template>

	<div class="fixed top-0 left-0 h-11 w-full drag"/>

	<div v-if="!this.hidden" class="relative h-11 w-full flex items-center justify-between bg-main-100 shrink-0 select-none text-xs">

		<div class="h-full flex items-center gap-4 overflow-hidden px-3"> <!-- Container -->

			<HamburgerButton
				@click="this.store.state.navigationMenuOpen = !this.store.state.navigationMenuOpen"
				:open="this.store.state.navigationMenuOpen"
				@focus="this.$handleFocusGroup(() => this.store.state.navigationMenuOpen = false)"
				focus-group="navigation-menu-controller"/>

			<div
				v-if="store.state.currentUser?.userData"
				class="w-fit hidden md:block text-white truncate overflow-hidden">
				
				{{ $st('app.hello') }} <span class="font-bold"> {{ ' ' + store.state.currentUser?.userData.name }} </span> 
			</div>

		</div>

		<div class="h-full flex items-center gap-4">

			<p v-if="this.userSubscription.active && !this.userSubscription.trialing" class="max-md:hidden text-white font-medium"> {{ this.$companyName }} Plan {{ this.userSubscription.name }} </p>
			
			<div
				v-if="this.userSubscription.balance !== undefined"
				class="h-7 flex items-center gap-2 bg-pro_dark text-white px-3 rounded-2xl shadow-weak_material cursor-pointer"
				@click="this.$router.push({ name: 'Payments' })">
				
				<i class="icon-diamond text-[20px]"/>
				{{ this.$st('payments.current-slans', { value: this.userSubscription.balance }) }}

			</div>
		
		</div>

		<div class="h-full w-[160px]"/> <!--- Window controls --->

	</div>

	<div v-if="store.state.embedded" class="fixed top-0 right-0 h-11 w-[160px] flex shrink-0 text-white ml-auto drag-none" style="z-index: 1000">

		<div class="h-full w-full flex items-center justify-center outline-none border-none cursor-default hover:bg-window-button" @click="this.MinimizeWindow()"><i class="icon-window-min text-base"/></div>
		<div class="h-full w-full flex items-center justify-center outline-none border-none cursor-default hover:bg-window-button" @click="this.MaximizeWindow()"><i class="icon-window-max text-base"/></div>
		<div class="h-full w-full flex items-center justify-center outline-none border-none cursor-default hover:bg-window-quit" @click="this.CloseWindow()"><i class="icon-window-quit text-base"/></div>

	</div>

</template>

<script>
import store from '@/store'
import HamburgerButton from '@/slango-multiverse/components/HamburgerButton'

export default {

	props: { hidden: Boolean },
  
	data: function() {

		return {

			store
		}
	},

	components: { HamburgerButton },

	computed: {

		getAppVersion() { return store.state.appVersion },

		CurrentRouteName() { return this.$route.name },

		userSubscription() { return store.getters.currentUserSubscription }
	},

	methods: {

		MinimizeWindow: async function() {

            if ( store.state.embedded ) { await this.$desktopAppComunication("Minimize") }
        },

        MaximizeWindow: async function() {

            if ( store.state.embedded ) { await this.$desktopAppComunication("Maximize") }
        },

        CloseWindow: async function() {

            if ( store.state.embedded ) { await this.$desktopAppComunication("CloseWindow") }
        },

		RemainingSubscriptionTime: function(date) {

			date = new Date(date) - new Date()

			const seconds = Math.floor(date / 1000)

			let interval = seconds / 31536000;

			const years = Math.floor(interval)
			if (interval > 1) { return this.$st('utils.timeleft', {date: '<span class="text-cta2-100">' + ( years + ' ' + this.$st('utils.year', years) ) + '</span>'}, years) }
			interval = seconds / 2592000

			const months = Math.floor(interval)
			if (interval > 1) { return this.$st('utils.timeleft', {date: '<span class="text-cta2-100">' + ( months + ' ' + this.$st('utils.month', months) ) + '</span>'}, months) }
			interval = seconds / 86400

			const days = Math.floor(interval)
			if (interval > 1) { return this.$st('utils.timeleft', {date: '<span class="text-cta2-100">' + ( days + ' ' + this.$st('utils.day', days) ) + '</span>'}, days) }
			interval = seconds / 3600

			const hours = Math.floor(interval)
			if (interval > 1) { return this.$st('utils.timeleft', {date: '<span class="text-cta2-100">' + ( hours + ' ' + this.$st('utils.hour', hours) ) + '</span>'}, hours) }
			interval = seconds / 60

			const minutes = Math.floor(interval)
			if (interval > 1) { return this.$st('utils.timeleft', {date: '<span class="text-cta2-100">' + ( minutes + ' ' + this.$st('utils.minute', minutes) ) + '</span>'}, minutes) }

			return 'now';
		},
	}
}
</script>

<style>

.ham-button:active {
  transform: translateY(2px);
}

.app-control-buttons-container {
  height: 100%;
  width: 180px;
  flex-shrink: 0;
}
</style>