<template>

    <DefaultView v-bind="view[view.current]">

        <template #subheader v-if="view.current === 'notifications'">

            <div class="w-full flex justify-between">

                <div class="w-full max-w-sm flex gap-2">

                    <InputField
                        variant="outlined_light"
                        size="sm"
                        class="w-full"
                        placeholder="Buscar notificación"
                        icon="icon-search"
                        v-model="notificationSearchKeyword"/>
                    
                    <Button variant="tinted" size="sm" text="Filtrar" icon="icon-filter" @click="showNotificationFilters = !showNotificationFilters" @focus="$handleFocusGroup(() => showNotificationFilters = false)" focus-group="notifications-filters">

                        <FloatingElement :position="{ top: 4, left: 0, translateY: true }" v-if="showNotificationFilters">

                            <FiltersBuilder :config="filters" focus-group="notifications-filters">
                            
                                <Button variant="secondary" size="xs" text="Aplicar" @click.stop="applyFilters" class="self-end"/>
                            
                            </FiltersBuilder>

                        </FloatingElement>

                    </Button>

                </div>

                <div class="flex flex-col gap-2">

                    <div class="flex items-center justify-end gap-2 flex-wrap">

                        <div class="flex items-center gap-2" v-if="selectedCertificatesText">

                            <i class="icon-certificate text-lg"></i>
                            <p class="text-xs">{{ selectedCertificatesText }}</p>

                        </div>

                        <Button variant="tinted" size="sm" text="Configuración" icon="icon-gear" @click="view.current = 'configuration'"/>
                        <Button
                                    variant="tinted"
                                    size="sm"
                                    :text="'Programar'"
                                    icon="icon-calendar"
                                    :reverse="true"
                                    :loading="scheduleLoading"/>

                        <Button variant="primary" size="sm" text="Actualizar notificaciones" @click="GetNotificationsFromDehu()" :loading="loading.scraping"/>

                    </div>

                    <!-- <p class="text-main-5 text-2xs italic font-light self-end">Última actualización {{ utils.formatDate({ date: new Date(), format: 'dd/mm/yyyy', hour: '2-digit', minute: '2-digit' }) }}</p> -->

                </div>

            </div>
            
        </template>

        <template #header v-else-if="view.current === 'configuration'">
            
            <div class="w-full max-w-sm flex gap-2">

                <InputField
                    variant="outlined_light"
                    size="sm"
                    class="w-full"
                    placeholder="Buscar certificado"
                    icon="icon-search"
                    v-model="certificateSearchKeyword"/>

            </div>
            
        </template>

        <div v-if="view.current === 'notifications'" class="h-full flex flex-col text-white">

            <!--- Notifications list --->

            <div v-if="loading.notifications && !notificationsList?.total" class="h-8 w-8 loading-spinner-main mx-auto shrink-0"/>

            <div
                v-else-if="!notificationsList?.total"
                class="w-full flex flex-col items-center gap-2">
                
                <div class="w-[140px] flex items-center justify-center aspect-square rounded-full bg-second-50 shrink-0 animate-fade">

                    <i class="icon-bell text-7xl text-second-75"/>

                </div>

                <p class="font-medium mt-4 text-center">Todavía no has actualizado ninguna notificación...</p>
                <p class="text-main-10 font-light text-center">Haz click en actualizar notificaciones o en configuración para seleccionar los certificados que deseas.</p>

            </div>

            <CustomTable v-else class="text-xs text-main-100 [&_td]:py-2 [&_td]:px-3 text-xs [&_th]:font-normal">

                <template #header>

                    <th v-for="notificationProperty in notificationProperties" :key="notificationProperty">

                        <div class="flex items-center gap-2 cursor-pointer hover:bg-main-100/10 py-0.5 px-1 rounded transition-colors" @click="changeSort(notificationProperty.key)">

                            {{ notificationProperty.label }}
                            <i class="icon-chevron-down text-3xs transition-transform" :class="{'hidden': notificationsQuery.sortby !== notificationProperty.key, '-rotate-180': notificationsQuery.sortdir === 'asc'}"/>

                        </div>

                    </th>

                    <th></th>

                </template>

                <tr
                    v-for="notification in notificationsList?.items" :key="notification"
                    class="cursor-pointer transition-opacity"
                    :class="{'opacity-40': loading.notifications}"
                    @click="notification.open = !notification.open">

                    <td>

                        {{ notification.titular }}

                    </td>

                    <td class="w-[30%]">

                        <p>{{ notification.organoEmisor }}</p>

                        <Collapsible :collapsed="!notification.open" duration="200">

                            <p class="text-2xs text-main-50">{{ notification.codProcedimiento }} <span class="icon-copy text-sm ml-1" @click.stop="$clipboard(notification.codProcedimiento)"/></p>

                        </Collapsible>

                    </td>

                    <td class="w-[30%]">

                        <p>{{ notification.concepto }}</p>

                        <Collapsible :collapsed="!notification.open" duration="200">

                            <p class="text-2xs text-main-50">
                                
                                ID: {{ notification._id }}

                                <span class="icon-copy text-sm ml-1" @click.stop="$clipboard(notification._id)"/>
                            
                            </p>

                        </Collapsible>

                    </td>

                    <td>{{ this.$formatDate({ date: notification.disposicion, format: 'dd/mm/yyyy' }) }}</td>

                    <td>{{ this.$formatDate({ date: notification.caducidad, format: 'dd/mm/yyyy' }) }}</td>

                    <td>

                        <Button size="xs" variant="tinted" icon="icon-next" @click.stop/>

                    </td>

                </tr>

            </CustomTable>

        </div>

        <!--- Configuration panel --->

        <div v-else-if="view.current === 'configuration'" class="h-full flex flex-col text-white">

            <UserResourcesManager 
                v-model="selectedCertificates"
                :externalSearchKeyword="certificateSearchKeyword"
                :searchInputField="false"
                :scrollLimit="false"
                :multiple="true"
                :persistentSelection="false"
                :shared="false"
                :selectable="false"/>

        </div>

    </DefaultView>

</template>

<script>
import InputField from '@/slango-multiverse/components/inputs/InputField'
import Button from '@/slango-multiverse/components/inputs/Button'
import CustomTable from '@/slango-multiverse/components/CustomTable'
import Collapsible from '@/slango-multiverse/components/Collapsible'
import FloatingElement from '@/slango-multiverse/components/FloatingElement'
import FiltersBuilder from '@/slango-multiverse/components/FiltersBuilder'
import UserResourcesManager from '@/components/UserResourcesManager'
import DefaultView from '@/layouts/DefaultView'
// eslint-disable-next-line
import utils from '@/slango-multiverse/helpers/utils'

import { globalProperties } from '@/main.js'
import { notifications } from '@/helpers/APIconnection'

export const NotificationsController = {

    GetNotificationsFromDehu: async function(selectedCerts) {

        if ( !selectedCerts?.length ) {

            globalProperties.$createToastMessage({
                            
                key: 'no-certs',
                text: 'Necesitas seleccionar un certificado para continuar.',
                type: 'error',
                alive: 3000,
                fixed: true,
                close: false
            })

            return
        }

        return await globalProperties.$desktopAppComunication("UpdateNotificationsList", selectedCerts)
    },

    GetNotifications: async function(notificationsQuery = {}, searchKeyword, filtersAsString) {

        const query = []

        query.push(`sortby=${notificationsQuery.sortby}&sortdir=${notificationsQuery.sortdir}`)

        if ( searchKeyword ) { query.push(`search=${searchKeyword}`) }
        if ( filtersAsString ) { query.push(this.filtersAsString) }

        const queryString = query.length ? '?' + query.join('&') : ''

        const response = await notifications.get(queryString)

        if ( response?.status === 200 ) { return response.data }
    },
}

export default {

    components: { InputField, Button, CustomTable, Collapsible, FloatingElement, FiltersBuilder, UserResourcesManager, DefaultView },

    data: function () {

        return {

            // >> View control config
            loading: {},
            view: {
                
                current: 'notifications',
                notifications: { title: 'Notificaciones' },
                configuration: {
                    title: 'Configuración',
                    description: 'Selecciona los certificados para actualizar sus notificaciones.',
                    icon: {
                        class: 'icon-previous text-2xl cursor-pointer transition-transform hover:-translate-x-0.5',
                        onclick: () => this.view.current = 'notifications'
                    }
                }
            },

            // >> Data
            notificationsList: undefined,
            selectedCertificates: undefined,

            // >> Query data
            notificationsQuery: { sortby: 'titular', sortdir: 'desc' },
            notificationSearchKeyword: undefined,
            showNotificationFilters: false,
            filtersAsString: undefined,
            certificateSearchKeyword: undefined,

            context: {},

            filters: [
                {
                    label: 'Fecha de Disposición',
                    type: 'date-range',
                    key: 'disposicion',
                    onClear: this.applyFilters
                },
                {
                    label: 'Fecha de Caducidad',
                    type: 'date-range',
                    key: 'caducidad',
                    onClear: this.applyFilters
                }
            ],

            notificationProperties: [

                { label: 'Titular', key: 'titular' },
                { label: 'Órgano emisor', key: 'organoEmisor' },
                { label: 'Concepto', key: 'concepto' },
                { label: 'Disposición', key: 'disposicion' },
                { label: 'Caducidad', key: 'caducidad' }
            ]
        }
    },

    mounted() {

        this.GetNotifications()
    },

    computed: {

        selectedCertificatesText() {

            const selected = this.selectedCertificates?.filter(c => c.selected)?.length

            if ( !selected ) return

            return selected > 1 ? `Certificados seleccionados: ${selected}` : 'Un certificado seleccionado'
        }
    },

    watch: {

        notificationSearchKeyword() { this.handleSpamOnNotificationsRequest() },
        filtersAsString() { this.handleSpamOnNotificationsRequest() }
    },

    methods: {

        handleSpamOnNotificationsRequest: function() { return utils.cancelableTimeout(this.context, 'get-notifications', 400, this.GetNotifications) },

        applyFilters: function() {

            this.filtersAsString = this.filters.map(f => f.toQuery()).join('&')
            this.handleSpamOnNotificationsRequest()
        },

        GetNotificationsFromDehu: async function() {

            const selectedCerts = this.selectedCertificates?.filter(c => c.selected)

            this.loading.scraping = true

            await NotificationsController.GetNotificationsFromDehu(selectedCerts)
            this.GetNotifications()

            this.loading.scraping = false
        },

        GetNotifications: async function() {

            this.loading.notifications = true

            this.notificationsList = await NotificationsController.GetNotifications(this.notificationsQuery, this.notificationSearchKeyword, this.filtersAsString)

            this.loading.notifications = false
        },

        changeSort: function (key) {

            if ( this.notificationsQuery.sortby !== key ) {

                this.notificationsQuery.sortby = key
                this.notificationsQuery.sortdir = 'desc'
            
            } else {

                this.notificationsQuery.sortdir = this.notificationsQuery.sortdir === 'desc' ? 'asc' : 'desc'
            }

            this.handleSpamOnNotificationsRequest()
        }
    }
}
</script>