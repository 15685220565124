import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import i18n from './i18n'
import { createGtm } from "vue-gtm"
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'
import vue3GoogleLogin from 'vue3-google-login'
import Icons from '@/components/Icons.vue'
import Button from '@/slango-multiverse/components/inputs/Button.vue'
import { InitApplication } from '@/helpers/app.js'

// Vuetify
import './vuetify/variables.scss'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import theme from './vuetify/theme.js'
import './slango-multiverse/main.css'

const component = {

    setup() {

		const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

		const recaptcha = async () => {

			await recaptchaLoaded()
			// Execute reCAPTCHA with action "login".
			await executeRecaptcha('login')
		}
  
		return { recaptcha }
    },

    template: ''
}

const vuetify = createVuetify({ components, directives, theme })

const app = createApp(App, component)

app.use(router, store, axios)
app.use(vuetify)
app.use(i18n)

// ==========| Google Login component |==========
app.use(vue3GoogleLogin, { clientId: process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID_WEB })

// ==========| Recaptcha |==========
app.use(VueReCaptcha, { siteKey: '6LfG0aggAAAAAPeK-jIOG07wKRzItYQU4QTtw6XA' })

// ==========| Google Analytics |==========
if ( process.env.VUE_APP_ENV === 'prod' ) {

	const gtm = createGtm({ id: "GTM-M4L3KPR", vueRouter: router })
	app.use(gtm)
}

export const globalProperties = app.config.globalProperties

InitApplication(app.config.globalProperties)
app.component('Icons', Icons)
app.component('Button', Button)

app.mount('#app')