<template>

    <div class="relative max-h-full w-full flex flex-col text-main-5">

        <div class="w-full flex flex-col gap-4 pb-3 border-b border-main-5">

            <div class="min-h-[36px] flex items-center justify-between flex-wrap gap-x-2 gap-y-4">

                <div class="flex items-center gap-3">

                    <i
                        v-if="!(this.disabledPreviousButton && !this.currentSection?.index) && !this.loading"
                        class="icon-previous text-2xl cursor-pointer transition-transform hover:-translate-x-0.5"
                        @click="this.OnBackButtonClick"/>

                    <div class="flex flex-col gap-1">

                        <div class="flex items-center gap-2">

                            <div class="text-xl font-semibold" v-html="this.title"/>

                            <div v-if="this.flow?.usageRatio" class="h-8 flex items-center gap-2 px-3 border-2 border-second-100 rounded-2xl">
        
                                <i class="icon-diamond text-lg"/>
                                <p class="text-xs truncate">{{ this.$st('payments.usage-type.' + (this.flow?.usageMeasureUnit ?? 'row'), { value: this.flow?.usageRatio }) }}</p>
                                
                            </div>

                        </div>

                        <div v-if="this.subtitle" class="text-xs font-light" v-html="this.subtitle"/>

                    </div>

                </div>
                
                <div id="header-buttons-teleport-slot" class="flex items-center flex-wrap gap-x-3 gap-y-2 ml-auto">

                    <Button
                        v-if="emailConfigEnabled && !this.loading"
                        variant="outlined_light"
                        size="sm"
                        :text="'Configurar envío'"
                        icon="icon-mail"
                        :reverse="true"
                        @click="this.modals.current = 'email-auth-modal'">

                        <div
                            v-if="emailConfiguration.google?.email || emailConfiguration.microsoft?.email"
                            class="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 flex items-center justify-center aspect-square p-1 bg-main-5 rounded-full animate-fade">

                            <i class="icon-link text-xl text-second-100"/>

                        </div>

                    </Button>

                    <div v-if="emailConfigEnabled && !this.loading" class="h-6 w-px bg-white/60 shrink-0"/>

                </div>

                <slot name="header-slot"/>

            </div>

            <slot name="subheader-slot"/>

        </div>

        <div class="relative h-full w-full flex overflow-hidden">

            <div class="w-full overflow-y-auto overflow-x-hidden scroll pr-2">

                <div class="w-full flex">

                    <StepbarColumn v-if="!this.loading"/>

                    <LoadingSection v-if="this.loading"/>

                    <div v-else-if="this.component" class="w-full overflow-hidden">

                        <component
                            :is="this.component"
                            :key="this.currentSection?.ref"
                            :props="{
                                
                                section: this.currentSection,
                                LaunchScript,
                                RepeatTask,
                                ChangeSection,
                                ToggleScheduler,
                                OnPaymentError,
                                ...this.currentSection.params,
                                InputComponents,
                                InputValues
                            }">
                        
                            <template v-slot:next="props"> <!----- Next button template ----->

                                <Button
                                    size="sm"
                                    :text="this.currentSection.execution ? $st('scriptlaunch.launchtask') : $st('general.next')"
                                    :icon="this.currentSection.execution ? '' : 'icon-chevron-right'"
                                    @click="props.callback"
                                    :loading="this.store.state.flowLauncherController.loading"/>

                            </template>

                            <template #repeat> <!----- Next button template ----->

                                <Button
                                    size="sm"
                                    :text="$st('scriptlaunch.repeattask')"
                                    @click="this.RepeatTask()"/>

                            </template>
                            
                            <template v-if="this.currentSection.scheduler" v-slot:scheduler="props"> <!----- Scheduler button template ----->

                                <Button
                                    variant="tinted"
                                    size="sm"
                                    :text="'Programar'"
                                    icon="icon-calendar"
                                    :reverse="true"
                                    @click="props.callback"/>

                            </template>

                            <template v-slot:instructions v-if="this.flow?.instructions"> <!----- Instructions button template ----->

                                <Button
                                    variant="outlined_light"
                                    size="sm"
                                    :text="$st('scriptlaunch.showinstructions')"
                                    :disabled="!this.flow?.instructions"
                                    @click="this.store.state.flowLauncherController.instructions = !this.store.state.flowLauncherController.instructions"/>

                            </template>

                            <template v-slot:console> <!----- Console button template ----->

                                <Button
                                    v-if="this.store.state.flowLauncherController.output?.consoleLog"
                                    size="sm"
                                    :variant="this.currentSection?.ref === 'error' ? 'primary' : 'plain'"
                                    :text="$st('scriptlaunch.showconsole')"
                                    icon="icon-warning"
                                    :color="this.currentSection?.ref === 'error' ? '' : 'text-cta2-100'"
                                    :reversed="true"
                                    @click="this.store.state.flowLauncherController.console = !this.store.state.flowLauncherController.console"/>

                            </template>

                        </component>

                        <div class="h-64 w-full shrink-0"></div>

                    </div>

                    <div v-else class="w-full flex flex-col items-center justify-center gap-4 pt-12">

                        <i class="icon-broken-link text-4xl"/>
                        <p class="text-center">Oops!&nbsp;&nbsp;Something went wrong.</p>

                    </div>

                </div>

            </div>

            <!-- <div class="absolute top-1/2 right-0 -translate-y-1/2 h-[calc(100%-48px)] mr-2">

                <div class="h-full w-[350px] transition-[width,opacity] duration-300" :class="{'!w-0 opacity-0': !openConfig}">

                    <div class="h-full w-[350px] flex flex-col gap-6 bg-main-5 rounded-lg p-4 text-main-100 shadow-material">

                        <p class="text-base font-bold">Configuraciones</p>

                        <ScrollShadow>

                            <div class="flex flex-col gap-2 overflow-y-auto scroll-main pr-0.5">

                                <div v-for="config in configurations" :key="config" class="group h-12 w-full flex items-center justify-between gap-2 rounded bg-main-100/10 text-xs py-2 px-4 shrink-0">
                                
                                    <p class="truncate">{{ config.name }}</p>

                                    <div class="hidden group-hover:flex items-center gap-2 animate-fade">

                                        <Button variant="plain_dark" icon="icon-download" @click="ImportConfiguration(config)"/>

                                        <Button variant="plain_dark" icon="icon-trash" @click="DeleteConfiguration(config)" :loading="config.removing"/>

                                    </div>

                                </div>

                                <InputField
                                    variant="default"
                                    size="lg"
                                    :quickClean="true"
                                    placeholder="Nueva configuración"
                                    v-model="this.newConfigurationName"
                                    :validationGroup="['configuration', true]"
                                    :required="true">
                                
                                    <Button variant="secondary" size="xs" icon="icon-plus" :rounded="true" @click="CreateConfiguration"/>

                                </InputField>

                            </div>

                        </ScrollShadow>

                    </div>

                </div>

                <div
                    class="group absolute top-1/2 right-0 -translate-y-1/2 h-9 w-9 flex items-center justify-center border border-main-5 rounded-full cursor-pointer transition-[right]"
                    @click="openConfig = !openConfig"
                    :class="{'right-[calc(100%+12px)]': openConfig}">

                    <i class="icon-chevron-right absolute text-lg pl-0.5 transition-[opacity,transform]" :class="{'group-hover:opacity-100 opacity-0 group-hover:-rotate-180': !openConfig}"/>
                    <i class="icon-gear absolute text-lg transition-[opacity,transform]" :class="[openConfig ? 'hidden' : 'group-hover:opacity-0 group-hover:-rotate-180']"/>

                </div>
                
            </div> -->
            
        </div>

    </div>

    <SchedulerModal v-if="this.modals.current === 'scheduler-modal'" :onclose="() => this.modals.current = undefined" :onSchedule="this.OnScheduleCreated"/>

    <Modal v-if="this.modals.current === 'email-auth-modal'" :onclose="() => this.modals.current = undefined">

        <div class="w-[500px] flex flex-col gap-6 text-main-100">

            <p class="text-2xl font-bold text-center">Configuración de envío</p>

            <div class="flex flex-col gap-3">

                <!-- <Button
                    v-if="emailConfiguration.google"
                    size="md"
                    variant="outlined_secondary"
                    :image="require('@/assets/icons/google.png')"
                    :text="`Desconectar de <b>${emailConfiguration.google.email}</b>`"
                    :reversed="true"
                    :stretch="true"/>

                <GoogleLogin v-else :callback="OnGoogleAuth" popup-type="TOKEN" class="w-full">

                    <Button
                        size="md"
                        variant="outlined_secondary"
                        :image="require('@/assets/icons/google.png')"
                        text="Iniciar sesión con Google"
                        :reversed="true"
                        :stretch="true"/>

                </GoogleLogin> -->

                <Button
                    v-if="emailConfiguration.microsoft?.email"
                    size="md"
                    variant="outlined_secondary"
                    :image="require('@/assets/icons/outlook.png')"
                    :text="`Desconectar de <b>${emailConfiguration.microsoft.email}</b>`"
                    :reversed="true"
                    :loading="emailConfiguration.microsoftLoading"
                    :stretch="true"
                    @click="OnMicrosoftDisconnect"/>

                <Button
                    v-else
                    size="md"
                    variant="outlined_secondary"
                    :image="require('@/assets/icons/outlook.png')"
                    text="Iniciar sesión con Outlook"
                    :reversed="true"
                    :stretch="true"
                    :loading="emailConfiguration.microsoftLoading"
                    @click="OnMicrosoftAuth"/>

            </div>

            <InputField label="Asunto" size="md" v-model="emailConfiguration.subject"/>
            
            <InputField label="Cuerpo del correo" size="lg" :textarea="true" v-model="emailConfiguration.body"/>

            <Button :text="$st('general.save')" class="self-end" @click="SaveEmailConfiguration"/>

        </div>

    </Modal>

</template>

<script>
import { defineAsyncComponent } from 'vue'

import LoadingSection from '@/components/script_templates/sections/LoadingSection'
import Button from '@/slango-multiverse/components/inputs/Button'
import InputField from '@/slango-multiverse/components/inputs/InputField'
import StepbarColumn from '@/slango-multiverse/components/StepbarColumn'
import SchedulerModal from '@/components/SchedulerModal'
import ScrollShadow from '@/components/ScrollShadow'
import Modal from '@/slango-multiverse/components/Modal'

import store from '@/store'
import { google, scripts } from '@/helpers/APIconnection'

export default {

    props: {
        
        ExecutionController: Function,
        disabledPreviousButton: Boolean,
        emailConfigEnabled: Boolean,
        robotekaScriptsEnvironment: Boolean
    },

    components: { LoadingSection, Button, InputField, StepbarColumn, SchedulerModal, ScrollShadow, Modal },

    computed: {

        title() { return this.currentSection.title ?? this.$st(this.flow?.name) },
        subtitle() { return (this.flow?.limits || this.flow?.description) ? this.$t(this.flow?.limits || this.flow?.description) : this.currentSection.subtitle },

        loading() { return store.state.flowLauncherController.loading },

        component() {

            const component = this.currentSection.template

            if ( !component ) return

            return defineAsyncComponent(() => import('@/components/script_templates/sections/' + component) )
        },

        InputComponents() {

            const components = {}
            this.flow.sectionSetup.builder.forEach((s) => s.builder?.forEach((c, componentIndex) => {

                if (this.robotekaScriptsEnvironment && c.template === 'SelectionDialog') c.config.embedded = false // File selection embedded option fixed in roboteka scripts
                if (!this.$embedded && c.template === 'SelectionDialog' && c.config.directory) c.hidden = true // Folder selection hidden in web
                
                if (!c.ref) { c.ref = c.key ?? Math.floor(Math.random() * 900000) + 100000 }
                components[c.ref] = c

                c.order = componentIndex
            }))

            return components
        },

        InputValues() {

            return Object.fromEntries(Object.values(this.InputComponents).map(c => { return [c.ref, c.value] })) || {}
        },
    },

    data: function() {

        return {

            store,
            flow: store.state.flowLauncherController.flow,

            currentSection: {},

            sections: {
                
                general: [

                    {
                        ref: 'error',
                        template: 'ErrorSection',
                        stepbar: false,
                    },
                    {
                        ref: 'output',
                        template: 'OutputSection',
                        stepbar: true,
                    },
                ]
            },

            modals: {

                current: undefined
            },

            emailConfiguration: {},

            openConfig: true,
            newConfigurationName: undefined,
            configurations: undefined
        }
    },

    created() {

        this.sections.dynamic = this.flow?.sectionSetup.builder.filter(step => !step.hidden) // Set the dynamic steps filtering by hidden
        this.sections.dynamic.forEach((item, index) => {

            item.index = index // Save index value in section data
            // >> Add here data for each step
        })

        this.ChangeSection({ index: 0 }) // >> Change to first section

        this.GetEmailConfiguration()
        // this.GetScriptsConfiguration()
    },

    methods: {

        ChangeSection: function(section, params) {

            // >> Change section by ref
            if ( section?.ref !== undefined ) { this.currentSection = this.sections.dynamic.concat(this.sections.general).find(section_ => section_.ref === section.ref) }

            else if ( section?.index !== undefined ) { this.currentSection = this.sections.dynamic[section.index] }

            else if ( section ) { // >> Change section to previous or following one

                const currentStepIndex = this.sections.dynamic.findIndex(section_ => this.currentSection.ref === section_.ref)

                this.currentSection = currentStepIndex !== undefined
                ? this.sections.dynamic[Math.min(Math.max(currentStepIndex + section, 0), this.sections.dynamic.length - 1)]
                : this.sections.dynamic[0]
            }

            else { this.currentSection = this.sections.dynamic[0].template === 'TutorialSection' ? this.sections.dynamic[1] : this.sections.dynamic[0] }

            // Save received params in section data
            if ( params ) { this.currentSection.params = Object.assign(this.currentSection.params ?? {}, params) }

            if ( !this.currentSection ) { this.currentSection = {} }
        },

        GetNormalizedInputValues: async function() {

            const fullValue = Symbol('fullValue');

            for (const item of Object.values(this.InputComponents)) {

                if (item.template === 'ExcelColumnSelector') {

                    const related = this.InputComponents[item.config.related];

                    if ( !related || related.template !== 'DataSchema' ) continue;

                    const relatedValue = related.getValue ? await related.getValue(fullValue) : related.value;

                    if (relatedValue.length && !Object.hasOwn(relatedValue[0], item.value)) {
                        relatedValue.forEach(element => { element[item.value] = element[fullValue][item.value]; })
                        related.value = relatedValue;
                    }
                }
                
                if ( item.getValue ) item.value = await item.getValue()
            }

            return this.InputValues
        },

        LaunchScript: async function(args, json) {
            
            store.state.flowLauncherController.loading = true
            store.state.flowLauncherController.ui = {}

            const executionParams = {

                step: this.currentSection.ref,
                token: store.state.flowLauncherController.output?.executionToken,
                arguments: args ?? await this.GetNormalizedInputValues(), // Object key-value
                inputs: this.InputComponents,
                json,
            }

            const response = await this.ExecutionController(executionParams)

            if ( !response?.status ) { store.state.flowLauncherController.loading = false; return }

            store.state.flowLauncherController.output = response?.data ?? {}
            store.state.flowLauncherController.output.status = response.status // Save in executionOutput the result status code

            // Execution response redirect controller >>

            if ( this.currentSection.router?.[response?.status] ) { this.ChangeSection({ ref: this.currentSection.router[response.status] }) } // Section router
            else if ( response?.status === 200 ) { this.ChangeSection({ ref: 'output' }) }
            else if ( response?.status === 242 ) { this.ChangeSection({ ref: 'error' }, { type: 'result' }) }
            else if ( response?.status === 400 || response?.status === 401 || response?.status === 500 ) { this.ChangeSection({ ref: 'error' }) }
            else if ( response?.status === 402 ) { this.OnPaymentError() }

            else if ( response?.status === 504 ) { this.ChangeSection({ ref: 'error' }, { type: 'timeout' }) }
            else { this.ChangeSection({ ref: 'error' }) }

            store.state.flowLauncherController.loading = false
        },

        RepeatTask: function() {

            store.state.flowLauncherController.console = false // Close console
            this.store.state.flowLauncherController.output = undefined // Reset output data on repeat

            const to = this.flow?.sectionSetup.resetTo ? { ref: this.flow?.sectionSetup.resetTo } : undefined
            this.ChangeSection(to)
        },

        OnBackButtonClick: function() {

            if ( this.currentSection.index === 0 ) { this.$router.go(-1) }
            else if ( this.currentSection.backTo ) { this.ChangeSection({ ref: this.currentSection.backTo }) }
            else { this.ChangeSection(-1) }
        },

        OnPaymentError: function() {

            this.store.state.ui.promptModal = {

                title: this.$st('payments.modals.insufficient-slans.title'),
                text: this.$st('payments.modals.insufficient-slans.text'),
                footer: [
                    { click: () => this.store.state.ui.promptModal = undefined, text: this.$st('payments.modals.insufficient-slans.continue'), variant: 'outlined', stretch: true },
                    { click: () => { this.$router.push({ name: 'Payments' }); this.store.state.ui.promptModal = undefined }, text: this.$st('payments.modals.insufficient-slans.see-plans'), stretch: true }
                ]
            }
        },

        // ====== Scheduler ======

        ToggleScheduler: function() {

            this.modals.current = this.modals.current === "scheduler-modal"
                ? undefined
                : 'scheduler-modal'
        },

        OnScheduleCreated: async function(data) {

            if ( !data.date ) return

            let normalizedDate = new Date(data.date)
            normalizedDate.setHours(data.hour)
            normalizedDate.setMinutes(data.minute)

            const localeDateString = normalizedDate.toLocaleString()
            const dateISOString = normalizedDate.toISOString()

            const executionParams = {
                arguments: await this.GetNormalizedInputValues(), // Object key-value
                inputs: this.InputComponents, 
                schedule: { ...data, localeDateString, scheduledDate: normalizedDate, dateISOString }
            }

            await this.ExecutionController(executionParams)

            this.modals.current = undefined
        },

        // ===============| Email Configuration |===============

        OpenEmailConfigurationModal: function() {

            this.GetEmailConfiguration()
            this.modals.current = 'email-auth-modal'
        },

        GetEmailConfiguration: async function() {

            const data = localStorage.getItem('flow-email-configuration')

            if ( data ) { this.emailConfiguration = JSON.parse(data) }

            this.emailConfiguration.microsoftLoading = true

            await this.$desktopAppComunication("GetConnectedMicrosoftAccount").then(email => {

                this.emailConfiguration.microsoft = { email }
                this.emailConfiguration.microsoftLoading = false
            })
        },

        SaveEmailConfiguration: function() {

            const { subject, body } = this.emailConfiguration

            localStorage.setItem('flow-email-configuration', JSON.stringify({ subject, body }))

            this.$desktopAppComunication("SaveEmailConfiguration", {subject, body}) 

            this.modals.current = undefined

            this.$createToastMessage({
                        
                key: 'flow-email-configuration',
                text: 'Se ha guardado la configuración para el envío de correo.',
                alive: 3000,
                fixed: true,
                close: false,
                leadingIcon: 'icon-mail'
            })
        },

        OnGoogleAuth: async function(res) {

            const response = await google.verify(res.access_token)
            
            if ( response?.status === 200 ) {

                this.emailConfiguration.google = response.data
            }
        },

        OnMicrosoftDisconnect: async function() {

            this.emailConfiguration.microsoftLoading = true

            await this.$desktopAppComunication("RemoveMicrosoftAccount")

            this.emailConfiguration.microsoft = undefined
            this.emailConfiguration.microsoftLoading = false
        },

        OnMicrosoftAuth: async function() {

            const response =  await this.$desktopAppComunication("SyncMicrosoftAccount")

            if ( response ) { this.emailConfiguration.microsoft = { email: response } }
        },

        GetScriptsConfiguration: async function() {

            const response = await scripts.getSettings(store.state.flowLauncherController.flow._id)

            if ( response?.status === 200 ) {

                this.configurations = response.data?.items

                Object.values(this.configurations ?? {}).forEach(c => {

                    if ( !(typeof c.settings === 'object' && !Array.isArray(c.settings)) ) { c.error = true }
                })
            }
        },

        CreateConfiguration: async function() {

            if ( !this.$validate('configuration') ) return

            const response = await scripts.createSetting(store.state.flowLauncherController.flow._id, { name: this.newConfigurationName, data: this.InputValues })

            if ( response?.status === 200 ) {

                this.newConfigurationName = undefined
            }
        },

        ImportConfiguration: function(config) {

            if ( typeof config.settings === 'object' && !Array.isArray(config.settings) ) {

                Object.entries(config.settings).forEach(s => {

                    const input = this.InputComponents[s[0]]

                    if ( input ) input.value = s[1]
                })
            }
        },

        DeleteConfiguration: async function(config) {

            config.removing = true

            const response = await scripts.deleteSetting(config._id)

            if ( response?.status === 200 ) {

                delete this.configurations[config._id]
            }

            config.removing = true
        }
    }
}
</script>

<style scoped>

</style>