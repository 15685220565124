<template>

    <InlineSVG :src="require(`@/assets/icons/${this.icon}.svg`)" v-if="this.icon"/>

</template>

<script>
import InlineSVG from 'vue-inline-svg'

export default {

    props: ['icon'],

    components: { InlineSVG },

    data: function () {

        return {}
    }
}
</script>

<style>
svg {
    height: 24px;
    width: 24px;
    flex-shrink: 0;
}
</style>