export const commonTechnologies = Object.freeze([
    
    'Discord',
    'Drive',
    'Dropbox',
    'Efficy',
    'Excel',
    'Gmail',
    'Google Calendar',
    'Holded',
    'Hootsuite',
    'Hubspot',
    'Instagram',
    'Linkedin',
    'Mailchimp',
    'Microsoft',
    'Monday',
    'Outlook',
    'PDF',
    'Pipedrive', 
    'PPT',
    'Slack',
    'Suma',
    'Teams',
    'Telegram', 
    'Whatsapp',
    'Word',
    'Zendesk',
    'Zoho',
    'Zoom'
])

export const provincias = Object.freeze({

    1: "Áraba/Álava",
    2: "Albacete",
    3: "Alacant",
    4: "Almería",
    5: "Ávila",
    6: "Badajoz",
    7: "Illes Balears",
    8: "Barcelona",
    9: "Burgos",
    10: "Cáceres",
    11: "Cádiz",
    12: "Castello",
    13: "Ciudad Real",
    14: "Córdoba",
    15: "A Coruña",
    16: "Cuenca",
    17: "Girona",
    18: "Granada",
    19: "Guadalajara",
    20: "Guipuzkoa",
    21: "Huelva",
    22: "Huesca",
    23: "Jaén",
    24: "León",
    25: "Lleida",
    26: "La Rioja",
    27: "Lugo",
    28: "Madrid",
    29: "Málaga",
    30: "Murcia",
    31: "Navarra",
    32: "Ourense",
    33: "Asturias",
    34: "Palencia",
    35: "Las Palmas",
    36: "Pontevedra",
    37: "Salamanca",
    38: "Tenerife",
    39: "Cantabria",
    40: "Segovia",
    41: "Sevilla",
    42: "Soria",
    43: "Tarragona",
    44: "Teruel",
    45: "Toledo",
    46: "Valencia",
    47: "Valladolid",
    48: "Bizcaia",
    49: "Zamora",
    50: "Zaragoza",
    51: "Ceuta",
    52: "Melilla",
})

export const TIMEOUT = 360000

export const DOCUMENT_BUILDER_REGEXES = Object.freeze({
    // eslint-disable-next-line
    fieldsRegex: /\[\[(?!\[)([^\[\]\s<]| |\[(?!\[)|\](?!\])|<(?!\/p>|br>))+\]\]/g,
    blockConstantRegex: /\[\[((DATE)?TIME|DATE)STAMP\]\]/g,
    blockIfRegex: /\[\[IF\((?<field>.*?)((?<op>(=|&gt;|&lt;))(?<value>.*?))?\?(?<ifTrue>.*?)(:(?<ifFalse>.*?))?\)\]\]/g,
    signatureRegex: /\[\[SIGNATURE\]\]/g,
})

export const STORAGE_KEYS = Object.freeze({
    REMOVE_ON_LOGOUT_REGEX: /^\$logoutRemove:/,
    DOCUMENT_BUILDER: Object.freeze({
        TEMPLATE_DRAFT: '$logoutRemove:DocumentBuilder.templateDraft',
    }),
});

export const CLICKANDCLICK_RUNNING_CHECK = 'tasklist /M "Slango Click And Click.dll"|Findstr Slango'