const invalidCharactersRegex = /^\s+|[<>:"/\\|?*]|[.\s]+$/g

const safeFile = {

    types: {
    
        txt: { mime: 'text/plain', extension: '.txt' },
        msj: { mime: '', extension: '.msj' },
        doc: { mime: 'application/msword', extension: '.doc' },
        docx: { mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', extension: '.docx' },
        xls: { mime: 'application/vnd.ms-excel', extension: '.xls' },
        xlsx: { mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', extension: '.xlsx' },
        ppt: { mime: 'application/vnd.ms-powerpoint', extension: '.ppt' },
        pptx: { mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', extension: '.pptx' },
        odt: { mime: 'application/vnd.oasis.opendocument.text', extension: '.odt' },
        ods: { mime: 'application/vnd.oasis.opendocument.spreadsheet', extension: '.ods' },
        odp: { mime: 'application/vnd.oasis.opendocument.presentation', extension: '.odp' },
        pdf: { mime: 'application/pdf', extension: '.pdf' },
        rtf: { mime: 'application/rtf', extension: '.rtf' },
        csv: { mime: 'text/csv', extension: '.csv' },
        jpg: { mime: 'image/jpeg', extension: ['.jpeg', '.jpg'] },
        png: { mime: 'image/png', extension: '.png' },
        gif: { mime: 'image/gif', extension: '.gif' },
        bmp: { mime: 'image/bmp', extension: '.bmp' },
        webp: { mime: 'image/webp', extension: '.webp' },
        svg: { mime: 'image/svg+xml', extension: '.svg' },
        tiff: { mime: 'image/tiff', extension: ['.tiff', '.tif'] }
    },

    sanitizeFilenames: (files) => {

        if ( !Array.isArray(files) ) { files = [files] }

        return files.map(f => new File([f], f.name.replace(invalidCharactersRegex, '')))
    },

    filterByTypes: (files, types = Object.values(safeFile.types)) => {

        if ( !Array.isArray(files) ) { files = [files] }
        if ( !Array.isArray(types) ) { types = [types] }

        return files.filter(f => {

            const fileType = { mime: f.type, extension: '.' + f.name.split('.').pop() }
            const type = types.find(t => t.mime.toLowerCase() === fileType.mime)

            if ( !type ) { return }

            return Array.isArray(type.extension) ? type.extension.includes(fileType.extension.toLowerCase()) : type.extension === fileType.extension.toLowerCase()
        })
    },

    secureFiles: (files) => {

        return safeFile.sanitizeFilenames(safeFile.filterByTypes(files))
    }
}

export default safeFile