<template>

    <div class="flex flex-col gap-2">

        <!---- Header ---->
        <div class="h-7 flex items-center justify-between">

            <p class="text-base pl-1">{{ data.label }}</p>

            <div class="flex items-center gap-2">

                <Button
                    v-if="ItemsStatus.selected?.length"
                    variant="outlined_light"
                    size="xs"
                    icon="icon-trash"
                    class="animate-fade"
                    text="Eliminar selección"
                    @click="data.trashCallback(ItemsStatus.selected)"/>

            </div>

        </div>

        <!---- List ---->
        <div class="flex flex-col p-2 bg-white rounded-lg text-main-100 shadow-material text-xs">

            <div class="h-10 flex items-center gap-4 px-3 mb-1">

                <SimpleCheckbox
                    :modelValue="ItemsStatus.allSelected"
                    @click="ToggleSelected"/>

                <InputField variant="default" size="xs" icon="icon-search" placeholder="Nombre" class="max-w-[200px]" v-model="searchKeyword"/>

                <div class="flex items-center gap-2 ml-auto cursor-pointer">

                    <p>Último creado</p>
                    <i class="icon-chevron-down text-base transition-transform" :class="{'rotate-180': false}"/>

                </div>

            </div>

            <div v-if="!FilteredItems?.length" class="p-3 text-main-50 font-light">No se han encontrado resultados</div>

            <div
                v-for="item in FilteredItems" :key="item"
                class="h-10 w-full flex items-center gap-4 px-3 bg-main-5/80 odd:bg-main-10/80">

                <SimpleCheckbox v-model="item.selected" @click="item.selected = !item.selected"/>

                <div class="w-full flex flex-col gap-1">

                    <p class="font-medium">{{ item.title }}</p>

                </div>

                    <div v-if="item.processed?.syncA3" class="flex items-center gap-2 [&_p]:truncate ml-auto">

                        <p class="text-2xs text-main-60">Sincronizado con A3</p>
                        <i class="icon-check-rounded text-lg text-second-100"/>

                    </div>

                     <div v-if="item.processed?.syncSistemaRed" class="flex items-center gap-2 [&_p]:truncate ml-auto" :class="{'cursor-pointer': item.lastError }"  @click="item.lastError ? this.$createToastMessage({ text: item.lastError, type: 'error', alive: 3000, close: false, fixed: true }) : undefined">

                        <p class="text-2xs text-main-60">Alta en Sistema Red</p>
                        <i v-if="item.lastError" class="icon-cross-rounded text-lg text-red"/>
                        <i v-else class="icon-check-rounded text-lg text-second-100" />

                    </div>

                <Button variant="secondary" size="xs" text="Ver Alta" icon="" :loading="item.loading" @click="data.openSubmissionCallback(item)"/>

                <p class="text-2xs whitespace-nowrap">{{ this.$formatDate({ date: item.createdAt, format: 'dd/mm/yy hh:mm' }) }}</p>

            </div>

        </div>

    </div>

</template>

<script>
import Button from '@/slango-multiverse/components/inputs/Button'
import InputField from '@/slango-multiverse/components/inputs/InputField'
import SimpleCheckbox from '@/slango-multiverse/components/inputs/SimpleCheckbox'

export default {

    components: { Button, SimpleCheckbox, InputField },

    props: { data: Object },

    data: function() {

        return {
            
            searchKeyword: ''
        }
    },

    computed: {

        FilteredItems() {

            if ( !this.data.items ) return []

            if ( !this.searchKeyword ) return this.data.items

            this.data.items.forEach(i => i.selected = false)

            return this.data.items.filter(p => p.title.toLowerCase().replaceAll(' ', '').includes(this.searchKeyword.toLowerCase().replaceAll(' ', '')))
        },

        ItemsStatus() {

            if ( !this.data.items ) return []

            return {

                allSelected: this.FilteredItems?.length && this.FilteredItems?.every(s => s.selected),
                selected: this.FilteredItems?.filter(s => s.selected),
            }
        },
    },

    methods: {

        ToggleSelected: function() {

            const toggleTo = !this.ItemsStatus.allSelected

            this.data.items.forEach(p => p.selected = toggleTo)
        },
    }
}
</script>