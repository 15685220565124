<template>

    <div class="h-full w-full flex text-sm text-main-5">

        <!---- Sidebar ---->

        <div v-if="this.showSidebar" class="h-full w-full max-w-[350px] flex flex-col gap-6 p-6 pr-0 border-r border-white/50">

            <ScrollShadow>

                <div class="h-full flex flex-col overflow-y-scroll scroll pr-6">

                    <!---- Slango flows ---->

                    <div class="h-fit w-full flex flex-col gap-4">

                        <div class="flex justify-between">

                            <p class="text-lg font-medium">{{ this.$companyName }} Flows <span v-if="!this.$embedded" title="Los robots para asesorias en web se encuentra actualmente en fase de BETA." class="italic text-cta2-100">| BETA</span></p>

                            <Button
                                class="animate-fade"
                                v-if="this.selectedFlow != undefined"
                                size="xs"
                                variant="tinted"
                                :icon="'icon-home'"
                                @click="this.selectedFlow = undefined"
                            />

                        </div>

                        <InputField variant="outlined_light" size="sm" :placeholder="$st('general.search')" icon="icon-search" v-model="this.flowsSearchKeyword"/>

                        <div v-if="this.loading.flows" class="h-6 w-6 loading-spinner-main mt-4 mx-auto shrink-0"/>

                        <div v-else class="w-full flex flex-col gap-2 mt-2">

                            <div v-for="flowGroup in this.GetNormalizedFlowsByGroup" :key="flowGroup" class="bg-main-10 rounded-lg">

                                <div
                                    class="h-10 flex items-center justify-between gap-2 rounded-md px-3 shadow-weak_material text-main-100 shrink-0 cursor-pointer bg-main-5 hover:bg-main-10 transition-colors"
                                    :class="{'!bg-cta2-10': flowGroup.open}"
                                    @click="flowGroup.open = !flowGroup.open">

                                    <p class="text-sm font-semibold">{{ flowGroup.name }}</p>

                                    <i class="icon-chevron-down text-xs transition-transform" :class="{'rotate-180': flowGroup.open}"/>

                                </div>

                                <Collapsible :collapsed="!flowGroup.open && !this.flowsSearchKeyword">

                                    <div class="flex flex-col gap-1 p-1.5">

                                        <div
                                            v-for="flow in flowGroup.flows" :key="flow"
                                            class="flex items-center justify-between px-3 py-3 bg-main-10 hover:bg-second-10 transition-colors text-xs text-main-75 hover:text-main-100 rounded shadow-weak_material cursor-pointer"
                                            :class="{'!bg-cta2-75 !text-main-100': this.selectedFlow?.id === flow.id}"
                                            @click="this.OnFlowSelect(flow.id)">

                                            <p>{{ flow.name }}</p>

                                            <div class="flex items-center gap-1">

                                                <i class="icon-play-rounded text-lg"/>

                                            </div>

                                        </div>

                                    </div>

                                </Collapsible>

                            </div>

                        </div>

                    </div>

                </div>

            </ScrollShadow>

            <div class="pr-6">

                <Button
                    v-if="store.state.externalAccess"
                    variant="tinted"
                    icon="icon-mr-robot"
                    :stretch="true"
                    :reversed="true"
                    v-bind="store.state.externalAccess"/>

            </div>

        </div>

        <!---- Flow content ---->

        <div v-if="loading.loadingFlowData" class="h-12 w-12 loading-spinner-main m-auto"/>

        <div v-else-if="this.selectedFlow" class="h-full w-full p-6 overflow-hidden">

            <DynamicBuilder
                :key="this.selectedFlow.id"
                :ExecutionController="this.OnLaunchFlow"
                :emailConfigEnabled="$embedded"
                :disabledPreviousButton="true">

                <template #subheader-slot>

                    <div class="flex items-center flex-wrap gap-2" v-if="this.selectedFlow.templateURL || this.selectedFlow.instructionsURL">
                            
                        <Button
                            v-if="this.selectedFlow.templateURL"
                            variant="outlined_light"
                            size="sm"
                            icon="icon-download"
                            text="Plantilla"
                            :reversed="true"
                            @click="$download({ url: this.selectedFlow.templateURL })"/>

                        <Button
                            v-if="this.selectedFlow.instructionsURL"
                            variant="outlined_light"
                            size="sm"
                            icon="icon-eye-open"
                            text="Instrucciones"
                            :reversed="true"
                            @click="$openExternal(this.selectedFlow.instructionsURL)"/>

                    </div>

                </template>

            </DynamicBuilder>

        </div>

        <div v-else class="w-full p-6 flex overflow-hidden"> <FlowsHistory/> </div>

    </div>

    <!--- Machine selector for flows without certificates selector, temporal until improvements --->
    <Modal
        v-if="requiredMachineModal"
        title="¿ Dónde quieres lanzar este robot ?"
        text="Selecciona una de las máquinas compartidas para realizar la programación."
        v-bind="requiredMachineModal">

        <div class="w-[500px]">

            <UserResourcesManager 
                v-model="requiredMachineModal.machine"
                :certificates="false"
                :machines="true"
                :selectable="true"
                :multiple="false"
                :currentMachine="false"/>

        </div>

    </Modal>

</template>

<script>
import InputField from '@/slango-multiverse/components/inputs/InputField'
import Button from '@/slango-multiverse/components/inputs/Button'
import Collapsible from '@/slango-multiverse/components/Collapsible'
import DynamicBuilder from '@/components/script_templates/DynamicBuilder'
import FloatingElement from '@/slango-multiverse/components/FloatingElement'
import Modal from '@/slango-multiverse/components/Modal'
import ScrollShadow from '@/components/ScrollShadow'
import UserResourcesManager from '@/components/UserResourcesManager'
import FlowsHistory from '@/components/FlowsHistory.vue'
import { LoadExcelFiles } from "@/helpers/excelManager"
import { FlowDetailsController } from '@/components/FlowDetails.vue'

import { flows } from '@/helpers/APIconnection'
import store from '@/store'
import { globalProperties } from '@/main.js'

export const FlowsController = {

    LaunchFlow: async function(data) { // flowInputs, flowId, sendOutputToServer
        
        const response = await globalProperties.$desktopAppComunication("LaunchFlow", data)

        if ( response ) { return FlowDetailsController.NormalizeLogData(response) }

        return response
    },

    Schedule: async function({
        flowId,
        flowInputs,
        machine,
        repeat,
        interval,
        every,
        scheduledDate = new Date(),
        localProcess,
        showScheduledMessage = true, // Show message when scheduled
        processName // Custom name of the scheduled process
    }) {

        if ( !machine || (Array.isArray(machine) && !machine.length) ) {

            globalProperties.$createToastMessage({ text: 'No se puede programar un robot sin especificar en qué dispositivo', type: 'error', close: false, fixed: true, alive: 3000 })
            return
        }

        const normalizedArguments = {...flowInputs}

        Promise.all(Object.entries(normalizedArguments).map(async arg => {

            if ( Array.isArray(arg[1]) && arg[1].some(value => value instanceof File && /(?<=^|\W)\.xlsx(?=$|\W)/.test(value.name)) ) {

                const data = await LoadExcelFiles(arg[1], 'launchFlow')
                const flatted = data.flatMap(file => file.data)
                arg[0] = { type: 'list', value: flatted }
            }
        }))

        const body = { flowInputs: normalizedArguments, machine: machine?._id, repeat, interval, every, localProcess }

        if ( scheduledDate instanceof Date ) { body.scheduledDate = scheduledDate.toISOString() }
        else { body.scheduledDate = scheduledDate }

        const response = await flows.schedule(flowId ? '/' + flowId : '', body)

        if ( response?.status === 200 ) {

            store.state.AutopilotGetSchedulesNow?.()

            if ( showScheduledMessage ) {

                store.state.ui.promptModal = {

                    title: '<i class=icon-mr-robot></i> Robot añadido a la cola',
                    text: `Se ha programado el robot <b>${processName || '-'}</b> en el dispositivo <b>${machine?.name || '-'}</b>.`,
                    bully: false,
                    onclose: () => store.state.ui.promptModal = undefined,
                    footer: [

                        { variant: 'outlined', ...store.state.externalAccess, afterClick: () => store.state.ui.promptModal = undefined },
                        { click: () => store.state.ui.promptModal = undefined, text: 'Entendido!', variant: 'primary' }
                    ]
                }
            }
        
        } else { globalProperties.$createToastMessage({ text: 'No se ha podido programar este robot', type: 'error', close: false, fixed: true, alive: 3000 }) }

        return response
    }
}

export default {

    components: { InputField, Button, Collapsible, DynamicBuilder, ScrollShadow, FloatingElement, Modal, FlowsHistory, UserResourcesManager }, // eslint-disable-line

    data: function() {

        return {

            showSidebar: true,
            flowsList: undefined,
            selectedFlow: undefined,
            loading: {},
            store,

            flowsSearchKeyword: undefined,

            requiredMachineModal: undefined, // Temporal machine selector for no certificate needed flows
        }
    },

    created() {

        this.GetFlows()
    },

    computed: {

        GetNormalizedFlowsByGroup() {

            if ( !this.Flows ) return

            const groups = {}

            this.Flows.forEach(f => {

                if ( f.group?._id in groups ) { groups[f.group._id].flows.push(f) }
                else { groups[f.group._id] = f.group; f.group.flows = [f] }
            })

            return groups
        },

        Flows() {

            if ( !this.flowsList ) return

            if ( this.flowsSearchKeyword ) {

                return this.flowsList.filter(f => ((f.group?.name??'').toLowerCase() + f.name.toLowerCase()).replaceAll(' ', '').includes(this.flowsSearchKeyword.toLowerCase().replaceAll(' ', '')))
            }

            return this.flowsList
        },

        previousSelectedFlow() { return this.$route.params.id }
    },

    watch: {

        selectedFlow() { if ( !this.selectedFlow ) this.$router.push({ ...this.$route, params: {} }) },

        previousSelectedFlow: {

            handler() {
                
                if (this.previousSelectedFlow) { this.GetSelectedFlowData(this.previousSelectedFlow) }
                else this.selectedFlow = undefined
            },
            immediate: true
        },
    },

    methods: {

        GetFlows: async function() {

            // >> Get flows from database

            this.loading.flows = true

            const response = await flows.get()

            if ( response?.status === 200 ) { this.flowsList = response.data }

            const types = {}
            this.flowsList.forEach(f => f.flowInputs.forEach(i => types[i.type] = true))

            this.loading.flows = false
        },

        FlowSectionBuilder: function(inputs) {

            if ( !inputs?.length ) return []

            const builder = Object.values(Object.groupBy(inputs, ({ template }) => {

                if ( template === 'FormToFlowConfig' ) return '2'
                return '1'
            }))

            builder[builder.length - 1].execution = true

            return builder.map((section, index) => { return { template: 'InputComponents', builder: section, execution: index === (builder.length - 1), scheduler: true } })
        },

        OnFlowSelect: function(id) {

            if (id) { this.$router.replace({ ...this.$route, params: { id } }) }
        },

        GetSelectedFlowData: async function(id) {

            if ( !id ) return
            
            this.loading.loadingFlowData = true
            
            const response = await flows.getById(id)
            
            if ( response?.status === 200 && response.data ) { this.selectedFlow = response.data }
            else if (this.previousSelectedFlow) {
                
                this.$router.replace({ ...this.$route, params: {} })
                this.loading.loadingFlowData = false
                return
            }

            this.loading.loadingFlowData = false

            store.state.flowLauncherController = {

                flow: {

                    ...this.selectedFlow,
                    sectionSetup: { builder: this.FlowSectionBuilder(this.selectedFlow.flowInputs) }
                }
            }
        },

        // ==========| Launch Flow controller |==========

        OnLaunchFlow: async function(params) {

            const remoteMachineSelected = this.RemoteCertificateMachineSelected(params.inputs)
            const data = { flowInputs: params.arguments, flowId: this.selectedFlow.id }

            if (params.schedule || remoteMachineSelected || !this.$embedded) {

                data.processName = this.selectedFlow.name
                if ( params.schedule ) { Object.assign(data, params.schedule) }

                if ( remoteMachineSelected ) { data.machine = remoteMachineSelected }

                else { // Machine selector management for flows without certificates selector, temporal until improvements

                    let machineResolve

                    const machinePromise = new Promise(r => machineResolve = r)

                    this.requiredMachineModal = {

                        footer: [
                            { text: this.$st('general.cancel'), variant: 'outlined', click: () => machineResolve(false) },
                            { text: this.$st('general.continue'), click: () => machineResolve(this.requiredMachineModal.machine) }
                        ]
                    }

                    const machineResponse = await machinePromise
                    this.requiredMachineModal = undefined

                    if ( machineResponse ) { data.machine = machineResponse }
                    else return
                }

                await FlowsController.Schedule(data)
            }

            else {

                data.emailConfiguration = params.emailConfiguration

                const response = await FlowsController.LaunchFlow(data)
                this.$addRobotLogNotification(response)
            }
        },

        RemoteCertificateMachineSelected: function(inputs = {}) {

            const certificateInput = Object.values(inputs).find(i => i.hasSharedCertificateSelected)

            return certificateInput?.value?.find(v => v.machine)?.machine
        }
    }
}
</script>
