<template>

    <div class="relative max-h-full overflow-y-auto scroll-hidden rounded-md">

        <table class="custom-table">

            <thead>

                <slot name="header"/>

            </thead>

            <tbody class="relative shadow-container">

                <slot/>

            </tbody>

        </table>

    </div>

</template>

<style>
.custom-table {

    position: relative;
    width: 100%;
    border-radius: 6px;
    text-align: start;
}

.custom-table thead, .custom-table tbody {

    width: 100%;
}

.custom-table thead {

    position: sticky;
    top: 0;
    z-index: 1;
    height: 28px;
}

.custom-table thead th {

    background: theme('colors.second.100');
    color: white;
    padding: 0 12px;
    text-align: start;
}

.custom-table tbody tr:nth-child(odd) {

    background: white;
}

.custom-table tbody tr:nth-child(even) {

    background: theme('colors.main.5');
}

.custom-table .shadow-container::after {

    content: '';
    position: absolute;
    bottom: 0;
    background-image: radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
}
</style>