<template>

    <div class="flex items-center gap-2" :class="{'flex-col': vertical}">

        <Button size="xs" variant="default" :icon="vertical ? 'icon-chevron-up' : 'icon-chevron-left'" @click="this.HandleSelector(vertical ? 1 : -1)"/>

        <p>{{ NormalizeValue }}</p>

        <Button size="xs" variant="default" :icon="vertical ? 'icon-chevron-down' : 'icon-chevron-right'" @click="this.HandleSelector(vertical ? -1 : 1)"/>

    </div>

</template>

<script>
import BaseInputComponent from './BaseInputComponent.vue'

export default {

    extends: BaseInputComponent,
    
    props: { vertical: Boolean, range: { type: Array, default: () => [0, 50] }, normalize: Function },

    computed: {

        NormalizeValue() {

           return this.normalize ? this.normalize(this.value) : this.value
        }
    },

    created() {

        if ( isNaN(this.value) ) { this.value = this.range[0] }

        this.value = Math.max(Math.min(this.value, this.range[1]), this.range[0])
    },

    data: function() {

        return {}
    },

    methods: {

        HandleSelector: function(to) {

            this.value += to

            if ( this.value < this.range[0] ) { this.value = this.range[1] }
            else if ( this.value > this.range[1] ) { this.value = this.range[0] } 
        },
    }
}
</script>